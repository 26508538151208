﻿.checkbox-group {  
  @include form-group;
}

.checkbox {
  $self: &;
  @include check-element;

  &__input {
    border-radius: var(--border-radius-xxs);
    
    &::before {
      @include icon($icon-size-small, $color: transparent, $image: url("../images/icons/icon-checkmark.svg"));
      @include center-absolute($icon-size-small, $icon-size-small);
    }

    &:checked::before {
      @include icon-color(var(--white));
    }
    
    &--on-light-bg {
      box-shadow: 0 0 0 var(--border-small) var(--black);
      
      &:focus-visible + .checkbox__label {
        outline-color: var(--black);
      }

      &:checked::before {
        @include icon-color(var(--black));
      }
    }
  }
  
  &--invalid {
    #{$self}__input {
      background-color: var(--red-25);
      
      &:checked {
        background-color: transparent;
      }
    }
  }
}
