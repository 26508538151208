.accordion {
    &__title {
        font-family: var(--font-family-futura-medium);
        line-height: var(--line-height-small);
        border-radius: var(--border-radius-xxs);
        cursor: pointer;
        font-family: var(--font-family-futura-medium);
        line-height: 1.154;
        list-style: none;
        margin: var(--border-small);
        padding: var(--spacing-xs) 0 var(--spacing-xs) 4.6rem;
        position: relative;
        transition: padding 0.4s ease-in-out;
        
        .expand-button {
            @include icon-plate(3.0rem, 3.0rem, $background-color: var(--orange), $is-circle: true);
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: calc((3.0rem/2) * -1);
            
            &::before {
                @include icon($icon-size-small, $image: url("../images/icons/icon-plus.svg"));
                @include center-absolute($icon-size-small, $icon-size-small);
            }
        }

        &:focus-visible {
            outline: var(--border-small) solid var(--black);
            padding-right: 1.4rem;
            padding-left: var(--spacing-large);

            .expand-button {
                left: 1.4rem;
            }
        }
    }

    &__details[open] .expand-button {
        background: var(--white);
        
        &::before {
            @include icon-image(url("../images/icons/icon-minus.svg"));
            background: var(--orange);
        }
    }
    
    &__content {
        padding-left: 4.8rem;
    }
    
    &__title:focus-visible + &__content {
        padding-top: var(--spacing-xs);
        padding-left: 6.2rem;
    }
}
