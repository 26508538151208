﻿.epoch {
  $self: &;

  display: flex;
  flex-direction: row;
  padding: 0 var(--spacing-medium);

  &--1800 {
    background-color: var(--red-75);
    color: var(--white);
  }

  &--1900 {
    background-color: #8B929D;
    color: var(--black);
  }

  &--2000 {
    background-color: #C5C8CE;
    color: var(--black);
  }

  &__item {
    position: relative;
    border-color: inherit;
    margin: 0 var(--spacing-medium);

    /*first child*/
    &:first-child {
      /*moment*/
      &.epoch__item--moment {
        &.epoch__item--early-1800 {
          margin-left: 4.2rem;
        }

        &.epoch__item--late-1800 {
          margin-left: 6rem;
        }

        &.epoch__item--early-1900 {
          margin-left: 4.2rem;
        }

        &.epoch__item--late-1900 {
          margin-left: 3rem;
        }

        &.epoch__item--early-2000 {
          margin-left: 1.2rem;
        }
      }
    }

    /*moment*/
    &.epoch__item--moment {
      &.epoch__item--early-1800 {
        width: clamp(21rem, 21vw, 42rem);
      }

      &.epoch__item--late-1800 {
        width: clamp(18rem, 18vw, 36rem);
      }

      &.epoch__item--early-1900 {
        width: clamp(21rem, 21vw, 42rem);
      }

      &.epoch__item--late-1900 {
        width: clamp(23.5rem, 23.5vw, 47rem);
      }

      &.epoch__item--early-2000 {
        width: clamp(27rem, 27vw, 54rem);
      }
    }

    &.illustration--old-suitcase {
      /*decoration*/
      &.epoch__item--decoration {
        &.epoch__item--early-1800 {
          width: clamp(27rem, 27vw, 56rem);
        }

        &.epoch__item--late-1800 {
          width: clamp(24rem, 24vw, 48rem);
        }

        &.epoch__item--early-1900 {
          width: clamp(27rem, 27vw, 54rem);
        }

        &.epoch__item--late-1900 {
          width: clamp(29.5rem, 29.5vw, 59rem);
        }

        &.epoch__item--early-2000 {
          width: clamp(33rem, 33vw, 66rem);
        }
      }

      /*moment*/
      &.epoch__item--moment {
        &.epoch__item--early-1800 {
          width: clamp(36rem, 36vw, 68rem);
        }

        &.epoch__item--late-1800 {
          width: clamp(33rem, 33vw, 62rem);
        }

        &.epoch__item--early-1900 {
          width: clamp(36rem, 36vw, 68rem);
        }

        &.epoch__item--late-1900 {
          width: clamp(38.5rem, 38.5vw, 73rem);
        }

        &.epoch__item--early-2000 {
          width: clamp(42rem, 42vw, 80rem);
        }
      }
    }

    &.illustration--seat-1800 {
      /*decoration*/
      &.epoch__item--decoration {
        &.epoch__item--early-1800 {
          width: clamp(30rem, 30vw, 60rem);
        }

        &.epoch__item--late-1800 {
          width: clamp(27rem, 27vw, 54rem);
        }

        &.epoch__item--early-1900 {
          width: clamp(30rem, 30vw, 60rem);
        }

        &.epoch__item--late-1900 {
          width: clamp(32.5rem, 32.5vw, 65rem);
        }

        &.epoch__item--early-2000 {
          width: clamp(36rem, 36vw, 72rem);
        }
      }

      /*moment*/
      &.epoch__item--moment {
        &.epoch__item--early-1800 {
          width: clamp(36rem, 36vw, 68rem);
        }

        &.epoch__item--late-1800 {
          width: clamp(33rem, 33vw, 62rem);
        }

        &.epoch__item--early-1900 {
          width: clamp(36rem, 36vw, 68rem);
        }

        &.epoch__item--late-1900 {
          width: clamp(38.5rem, 38.5vw, 73rem);
        }

        &.epoch__item--early-2000 {
          width: clamp(42rem, 42vw, 80rem);
        }
      }
    }

    &.illustration--seat-1900 {
      /*decoration*/
      &.epoch__item--decoration {
        &.epoch__item--early-1800 {
          width: clamp(28rem, 28vw, 56rem);
        }

        &.epoch__item--late-1800 {
          width: clamp(25rem, 25vw, 50rem);
        }

        &.epoch__item--early-1900 {
          width: clamp(28rem, 28vw, 56rem);
        }

        &.epoch__item--late-1900 {
          width: clamp(30.5rem, 30.5vw, 61rem);
        }

        &.epoch__item--early-2000 {
          width: clamp(34rem, 34vw, 68rem);
        }
      }

      /*moment*/
      &.epoch__item--moment {
        &.epoch__item--early-1800 {
          width: clamp(36rem, 36vw, 68rem);
        }

        &.epoch__item--late-1800 {
          width: clamp(33rem, 33vw, 62rem);
        }

        &.epoch__item--early-1900 {
          width: clamp(36rem, 36vw, 63rem);
        }

        &.epoch__item--late-1900 {
          width: clamp(38.5rem, 38.5vw, 73rem);
        }

        &.epoch__item--early-2000 {
          width: clamp(42rem, 42vw, 80rem);
        }
      }
    }

    &.illustration--seat-2000 {
      /*decoration*/
      &.epoch__item--decoration {
        &.epoch__item--early-1800 {
          width: clamp(30rem, 30vw, 60rem);
        }

        &.epoch__item--late-1800 {
          width: clamp(30rem, 30vw, 60rem);
        }

        &.epoch__item--early-1900 {
          width: clamp(33rem, 33vw, 66rem);
        }

        &.epoch__item--late-1900 {
          width: clamp(35.5rem, 35.5vw, 71rem);
        }

        &.epoch__item--early-2000 {
          width: clamp(33rem, 33vw, 66rem);
        }
      }

      /*moment*/
      &.epoch__item--moment {
        &.epoch__item--early-1800 {
          width: clamp(33rem, 33vw, 62rem);
        }

        &.epoch__item--late-1800 {
          width: clamp(33rem, 33vw, 62rem);
        }

        &.epoch__item--early-1900 {
          width: clamp(36rem, 36vw, 68rem);
        }

        &.epoch__item--late-1900 {
          width: clamp(38.5rem, 38.5vw, 73rem);
        }

        &.epoch__item--early-2000 {
          width: clamp(42rem, 42vw, 80rem);
        }
      }
    }
  }

  &__content {
    position: absolute;
    text-align: center;
    top: clamp(5rem, 5vw, 9rem);
  }
}
