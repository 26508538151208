﻿.rss-block {
  &__content {
    @include block-card-light-green-blue;
    max-width: var(--max-width-content-half);
  }
  
  &__text {
    *:last-child {
      margin-bottom: 0;
    }
  }
}

// On other background colors
.section-block--bg-light-green-blue .rss-block__content {
  background-color: var(--green-blue-50);
}
