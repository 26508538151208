﻿.contact-block {
  &__content {
    @include block-card-light-green-blue;
    max-width: var(--max-width-content-half);
  }
}

.section-block--bg-light-green-blue .contact-block__content {
  @include block-card-color(var(--green-blue-50), var(--black), var(--black));
}
