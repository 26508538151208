﻿.search-page {
  &__form {
    @include container;
    margin-top: var(--spacing-medium);
  }
  
  &__container {
    transition: opacity 0.3s ease-in-out;
    min-height: 500px;
  }
  
  &__form,
  &__result-container {
    max-width: calc(var(--max-width-box) + (var(--spacing-small) * 2));

    @media(min-width: $md) {
      max-width: calc(var(--max-width-box) + (var(--spacing-medium) * 2));
    }

    @media(min-width: $lg) {
      max-width: calc(var(--max-width-box) + (var(--spacing-large) * 2));
    }

    @media(min-width: $xl) {
      max-width: calc(var(--max-width-box) + (var(--spacing-xl) * 2));
    }
  }
  
  .filter {
    margin-bottom: var(--spacing-small);
  }
  
  &__result-info {
    @media(min-width: $md) {
      margin-bottom: var(--spacing-medium);
    }
  }
  
  &__result-list {
    @include clear-list;
    row-gap: var(--spacing-small);

    @media(min-width: $md) {
      row-gap: var(--spacing-medium);
    }
  }
  
  &__show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--spacing-large);

    @media(min-width: $md) {
      margin-top: var(--spacing-xl);
    }
  }

  .spinner {
    text-align: center;
    margin: 20px 0;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: 450px;

    svg {
      display: block;
      margin: 0 auto;
      width: 200px;
      height: 200px;
    }

    &--show {
      opacity: 1;
      height: 200px;
    }
  }
}