.page-header {
  $self: &;

  width: 100%;
  
  &,
  &--show-divider-behind-content-items#{$self}--has-divider {
    padding-bottom: var(--spacing-large);
    
    @media(min-width: $md) {
      padding-bottom: var(--spacing-xl);
    }

    @media(min-width: $xl) {
      padding-bottom: var(--spacing-xxl);
    }
  }

  &--has-divider {
    padding-bottom: 0;
  }
  
  &--bg-dark-purple {
    background-color: var(--dark-purple);
  }

  &__faded-overlay {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 168px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

    @media(min-width: $header-desktop-breakpoint) {
      height: 238px;
    }
  }
  
  &__image-container {
    width: 100%;
    height: 430px;
    max-height: 60vh;
    position: relative;

    @media(min-width: $header-desktop-breakpoint) {
      height: 650px;
    }
    
    img {
      height: 100%;
      object-fit: cover;
    }
    
    & + #{$self}__text-container {
      padding-top: var(--spacing-xl);
    }
  }
  
  .breadcrumbs + &__text-container {
    padding-top: var(--spacing-medium);
  }

  &__byline {
    @media(min-width: $xl) {
      font-size: var(--font-size-small);
    }
  }

  &__caption {
    @media(min-width: $xl) {
      font-size: var(--font-size-small);
    }
  }
  
  &__text-container,
  &__category-list {
    @include container;
  }
  
  &__date-field {
    display: block;
    font-family: var(--font-family-futura-medium);
    font-size: var(--font-size-small);
    text-transform: uppercase;
    margin-bottom: var(--margin-small-text-10);
  }
  
  &__text-container {
    max-width: calc((var(--max-full-width-text) + 10rem) + (var(--spacing-small) * 2));
    text-align: center;
    padding-top: var(--spacing-xxl);

    @media(min-width: $md) {
      max-width: calc((var(--max-full-width-text) + 10rem) + (var(--spacing-medium) * 2));
      padding-top: calc(var(--spacing-base) * 16);
    }

    @media(min-width: $lg) {
      max-width: calc((var(--max-full-width-text) + 10rem) + (var(--spacing-large) * 2));
    }

    @media(min-width: $xl) {
      max-width: calc((var(--max-full-width-text) + 10rem) + (var(--spacing-xl) * 2));
    }
    
    *:last-child {
      margin-bottom: 0;
    }
  }

  &__category-list {
    margin-top: var(--spacing-medium);
  }
  
  &__countdown {
    @include container;
    margin-top: var(--spacing-medium);

    @media(min-width: $md) {
      margin-top: var(--spacing-large);
    }

    @media(min-width: $xl) {
      margin-top: var(--spacing-xl);
    }
  }
  
  &__information {
    position: relative;
  }

  &--faded {
    #{$self}__image-container {
      height: 508px;
      max-height: unset;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      @media(min-width: $header-desktop-breakpoint) {
        height: 692px;
      }
      
      & + * {
        padding-top: 0;
        margin-top: calc(448px - var(--header-height-mobile));

        @media(min-width: $header-desktop-breakpoint) {
          margin-top: calc(630px - var(--header-height-desktop));
        }
      }
    }

    #{$self}__caption {
      display: none;

      @media(min-width: $lg) {
        display: block;
      }
    }

    #{$self}__text-container {
      max-width: calc(var(--max-width-content-half) + (var(--spacing-small) * 2));
      padding-top: 0;

      @media(min-width: $md) {
        max-width: calc(var(--max-width-content-half) + (var(--spacing-medium) * 2));
      }

      @media(min-width: $lg) {
        max-width: calc(var(--max-width-content-half) + (var(--spacing-large) * 2));
      }

      @media(min-width: $xl) {
        max-width: calc((var(--max-full-width-text) + 10rem) + (var(--spacing-xl) * 2));
      }
      
      .heading {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* Spacing for heading when there is a header on the page */
.header + .main-content .page-header {
  &__text-container {
      padding-top: var(--spacing-xl);
  }

  .breadcrumbs + .page-header__text-container {
    padding-top: var(--spacing-medium);
  }
}

/* Remove top spacing for heading in faded page header */
.header + .main-content .page-header--faded .page-header__text-container {
  padding-top: 0;
}
