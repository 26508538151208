@mixin collapse-section {
  padding-top: 0;

  @media(min-width: $md) {
    margin-top: -2rem;
  }

  @media(min-width: $xl) {
    margin-top: calc(var(--spacing-medium) * -1);
  }
}

.section-block {
  $self: &;
  
  @include section-spacing;
  position: relative;
  
  &.content-area-item {
    display: block;
  }
  
  &__container {
    @include container;
    z-index: 2;
    position: relative;
    
    &--has-sidebar {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      gap: var(--spacing-large);
    }
    
    &--faq .content-area {
      row-gap: var(--spacing-small);
      
      .content-area-item {
        margin-bottom: var(--spacing-small);

        @media(min-width: $xl) {
          margin-bottom: var(--spacing-medium);
        }
        
        &.accordion-block,
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
  &--bg-white,
  &--bg-light-green-blue,
  &--bg-dark-orange,
  &--bg-dark-purple {
    z-index: 3;
  }

  &--bg-white {
    background-color: var(--white);
    
    & + & {
      @include collapse-section;
    }
  }

  &--bg-light-green-blue {
    background-color: var(--green-blue-20);

    & + & {
      @include collapse-section;
    }
  }

  &--bg-black {
    & + & {
      @include collapse-section;
    }
  }
  
  &--bg-smoke {
    overflow-x: clip;
  }

  &--bg-dark-orange {
    background-color: var(--dark-orange);

    & + & {
      @include collapse-section;
    }
  }

  &--bg-dark-purple {
    background-color: var(--dark-purple);

    & + & {
      @include collapse-section;
    }
  }
  
  &--has-divider {
    padding-bottom: 0;
  }

  &__heading {
    width: 100%;
    position: relative;
  }
  
  &__smoke {
    display: block;
    width: 64.7rem;
    height: 98.4rem;
    position: absolute;
    right: calc(var(--spacing-medium) * -1);
    top: calc(var(--spacing-base) + var(--spacing-xs));
    background: url("../images/smoke-mobile.svg") no-repeat;
    z-index: 1;

    @media(min-width: $sm) {
      right: 50%;
      margin-right: calc((64.7rem /2) * -1);
    }

    @media(min-width: $md) {
      width: 196.5rem;
      height: 127rem;
      margin-right: calc((196.5rem /2) * -1);
      background: url("../images/smoke.svg") no-repeat;
    }
  }
}

// Set less top spacing for first black section
.page-header + .section-block--bg-black,
.page-header + [class*="__container"] .section-block--bg-black:first-child,
.page-header--has-divider.page-header--show-divider-behind-content-items + .section-block--bg-black,
.page-header--has-divider.page-header--show-divider-behind-content-items + [class*="__container"] .section-block--bg-black:first-child,
.section-block--has-divider.page-header--show-divider-behind-content-items + .section-block--bg-black {
  @include collapse-section;
}

// Reset spacing for first black section if header or section above has divider
.page-header--has-divider + .section-block--bg-black,
.page-header--has-divider + [class*="__container"] .section-block--bg-black:first-child,
.section-block--has-divider + .section-block--bg-black {
  padding-top: var(--spacing-large);
  margin-top: 0;

  @media(min-width: $md) {
    padding-top: var(--spacing-xl);
  }

  @media(min-width: $xl) {
    padding-top: var(--spacing-xxl);
  }
}

// Let smoke be set a bit higher for black section right after page header
.page-header + .section-block--bg-smoke,
.page-header + [class*="__container"] .section-block--bg-smoke:first-child {
  .section-block__smoke {
    top: calc((var(--spacing-small) + var(--spacing-xs)) * -1);
    
    @media(min-width: $md) {
      top: calc(var(--spacing-xxl) * -1);
    }
  }
}

// Reset top alignment for smoke if page header has divider
.page-header--has-divider + .section-block--bg-smoke,
.page-header--has-divider + [class*="__container"] .section-block--bg-smoke:first-child {
  .section-block__smoke {
    @media(min-width: $md) {
      top: calc(var(--spacing-base) + var(--spacing-xs));
    }
  }
}

// Set a bit more spacing at the bottom for the last black section before the footer
.section-block--bg-black:last-of-type {
  padding-bottom: var(--spacing-xxl);

  @media(min-width: $md) {
    padding-bottom: calc(var(--spacing-base) * 16);
  }
}
