.audio-block {
  &__content {
    width: 100%;
    max-width: var(--max-full-width-text);
    
    audio, iframe {
      width: 100%;
    }
    
    iframe {
      height: 48px;
    }
  }
}