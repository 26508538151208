@mixin focus-frame($outline-color: var(--orange)) {
  border-radius: var(--border-radius-xxs);
  outline: var(--border-small) solid $outline-color;
  outline-offset: var(--border-small);
}

@mixin link-color($color) {
  color: $color;

  &::before {
    @include icon-color($color);
  }

  &:focus-visible {
    outline-color: $color;
  }
}
