﻿.EPiServerForms {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100% !important;
    max-width: var(--max-width-box) !important;
    background-color: var(--black);
    padding: var(--spacing-medium) var(--spacing-small);
    border-radius: var(--border-radius-large);
    color: var(--white) !important;

    @media(min-width: $md) {
        padding: var(--spacing-xl) var(--spacing-large);
        border-radius: var(--border-radius-xl);
    }

    .Form__MainBody {
        width: 100% !important;
    }
    
    .Form__Title,
    .section-block--bg-white .Form__Title.heading,
    .section-block--bg-light-green-blue .Form__Title.heading,
    {
        color: var(--white);
    }
    
    .Form__Description {
        margin-bottom: var(--spacing-medium) !important;
    }
    
    .Form__Element {
        margin-bottom: var(--spacing-small) !important;
        
        &:last-child {
            margin-bottom: 0 !important;
        }
        
        &__Caption {
            &:after {
                content: "" !important;
            }
        }

        &__ValidationError {
            color: var(--white) !important;
            font-size: var(--font-size-xs) !important;
            line-height: var(--line-height-medium) !important;
            
        }
    }
    
    .FormFileUpload__Input {
        width: 100%;
        border: none;
    }

    .FormRange {
        &__Wrapper {
            width: 100% !important;

            &:has(input:focus-visible) {
                border: var(--border-medium) solid var(--black);
                padding: 0 1.3rem;
                border-radius: var(--border-radius-input);
            }
        }
    }

    .Form__Status {
        &__Message {
            display: flex !important;
            align-items: center;
            border-radius: var(--border-radius-large);
            padding: 4rem!important;
            padding-left: calc(var(--spacing-medium) + $icon-size-xl + var(--spacing-small)) !important;
            flex-direction: column;
            margin: var(--spacing-base) 0 !important;
            color: var(--black) !important;
            background-repeat: no-repeat;
            background-size: $icon-size-xl;
            background-position: var(--spacing-medium) center;

            @media(min-width: $sm) {
                flex-direction: row;
            }

            &.hide {
                display: none !important;
            }

            p {
                margin-bottom: 0;
                flex: 1;
            }
        }
    }

    .Form__Success__Message {
        border-color: var(--green-20) !important;
        background-color: var(--green-20) !important;
        background-image: url("../images/icons/icon-input-valid.svg");
    }

    .Form__Warning__Message {
        border-color: var(--orange-20) !important;
        background-color: var(--orange-20) !important;
        background-image: url("../images/icons/icon-input-invalid.svg");
    }

    .ValidationFail {
        color: var(--white) !important;
        
        input,
        textarea {
            border-color: var(--red-25) !important;
            background-color: var(--red-25) !important;
            background-image: url("../images/icons/icon-input-invalid.svg") !important;
        }
        
        input {
            @extend .input-text__input--with-icon;
        }
        
        textarea {
            @extend .input-text__textarea--with-icon;
        }

        .FormFileUpload__Input {
            border: var(--border-small) solid var(--red);
            border-radius: var(--border-radius-input);
            padding: var(--spacing-small);
            color: var(--red);
            font-size: var(--font-size-xs);
        }
    }

    .FormSubmitButton:disabled {
        opacity: 1 !important;
    }
}
