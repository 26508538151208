.disturbance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-small);
  background-color: var(--orange-75);
  padding: var(--spacing-xs) calc(var(--spacing-small) - 1.2rem) var(--spacing-xs) var(--spacing-small);

  @media(min-width: $header-desktop-breakpoint) {
    gap: var(--spacing-medium);
    padding-right: calc(var(--spacing-medium) - 1.2rem);
    padding-left: var(--spacing-medium);
  }

  @media(min-width: $xl) {
    gap: var(--spacing-large);
    padding-right: calc(var(--spacing-large) - 1.2rem);
    padding-left: var(--spacing-large);
  }
  
  &--hidden {
    display: none;
  }
  
  &__message {
    font-size: var(--font-size-small);
    color: var(--black);
    
    a {
      color: var(--black);
      
      &:focus {
        outline-color: var(--black);
      }
    }
    
    p {
      margin-bottom: var(--margin-small-text-10);
    }
    
    *:last-child {
      margin-bottom: 0;
    }
  }
  
  &__close-button {
    border-radius: var(--border-radius-small);
    transition: background-color var(--transition-timing) ease-in-out;
    
    &-icon {
      @include icon-plate-size(var(--click-surface-min-size), var(--click-surface-min-size));

      &::before {
        @include icon-color(var(--black));
        @include icon-size(2rem);
        @include center-absolute(2rem, 2rem);
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
    
    &:focus {
      outline: var(--border-small) solid var(--black);
      outline-offset: 0;
    }
  }
}

.main-navigation-is-open .disturbance {
  width: calc(100% + var(--scrollbar-width));
  padding-right: calc(var(--spacing-small) - 1.2rem + var(--scrollbar-width));

  @media(min-width: $header-desktop-breakpoint) {
    padding-right: calc(var(--spacing-medium) - 1.2rem + var(--scrollbar-width))
  }

  @media(min-width: $xl) {
    padding-right: calc(var(--spacing-large) - 1.2rem + var(--scrollbar-width))
  }
}
