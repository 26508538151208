﻿.subscribe-form-block {
  &__content {
    @include block-card-light-green-blue;
    max-width: var(--max-width-content-half);
  }
  
  &__button-container {
    margin-top: calc(var(--spacing-medium) - var(--spacing-base) - var(--border-small));
  }
  
  &__response-message {
    margin: var(--spacing-medium) 0 0;
    padding: var(--spacing-small) var(--spacing-small) var(--spacing-small) calc(var(--spacing-small) + $icon-size-large + var(--spacing-small));
    background-repeat: no-repeat;
    background-size: $icon-size-large;
    background-position: var(--spacing-small) center;
    border: var(--border-small) solid var(--black);
    border-radius: var(--border-radius-large);
    font-size: var(--font-size-small);
    
    &--error {
      background-color: var(--orange-20);
      background-image: url("../images/icons/icon-input-invalid.svg");
    }
    
    &--success {
      background-color: var(--green-20);
      background-image: url("../images/icons/icon-input-valid.svg");
    }
  }
}

// On other background colors
.section-block--bg-light-green-blue .subscribe-form-block__content {
  background-color: var(--green-blue-50);
}
