/* Base style and resets */

// General
html,
*,
*::after,
*::before {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  opacity: 1;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

html,
body {
  min-height: 100vh;
  width: 100%;
}

html {
  font-size: 62.5%;
}

body {
  overflow-x: hidden;
  background-color: var(--black);
}

// Images
img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

// Tables
table {
  border-collapse: collapse;
  text-align: left;
  vertical-align: top;
}

// Forms
button,
[type="button"],
[type="submit"],
[type="reset"] {
  cursor: pointer;
}

button,
input,
textarea {
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

// Focus style
:focus:not(:focus-visible) {
  outline: none;
}

:focus-visible {
  border: none;
  outline: none;
  outline-offset: 0;
}

/* Helper classes */

.clearfix {
  display: flow-root;
}

// Hide elements from everyone
.hidden,
[hidden] {
  display: none !important;
}

// Hide elements visually
.sr-only {
  @include sr-only;
}

// Non scroll on body as a full screen modal is displayed
.has-full-screen-modal {
  overflow: hidden;
  pointer-events: none;
  touch-action: none;
}
