﻿.captcha-element {

  &__refresh {
    order: 2;
  }

  &__img {
    margin-bottom: var(--spacing-base);
    order: 3
  }

  &__input {
    @include input-text;
    order: 3;
  }
}