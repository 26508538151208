﻿.timeline-window {
    margin: auto;
    height: 140px;
    width: 210px;
    overflow: hidden;

    &--early-1800 {
        height: clamp(140px, 14vw,280px);
        width: clamp(21rem,21vw,42rem);
        outline: 10px solid #DB7E4F;
    }

    &--late-1800 {
        height: clamp(140px,14vw,280px);
        width: clamp(18rem,18vw,36rem);
    }

    &--early-1900 {
        height: clamp(140px,14vw,280px);
        width: clamp(21rem,21vw,42rem);
    }

    &--late-1900 {
        height: clamp(140px,14vw,280px);
        width: clamp(23.5rem,23.5vw,47rem);
        border-radius: 15px;
        overflow: hidden;
    }

    &--early-2000 {
        height: clamp(17.5rem,17.5vw,35rem);
        width: clamp(27rem,27vw,54rem);
        border-radius: 20px;
        overflow: hidden;
    }

    &__view {
        height: 100%;
        position: relative;

        figure {
            height: 100%;

            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        margin: auto;
        height: 100%;
        width: 100%;
        text-align: center;
        padding-top: 25px;

        h2 {
            margin-bottom: 5px;
        }
    }

    .view-woods {
        background-image: url("../images/timeline/views/woods.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .view-mountains {
        background-image: url("../images/timeline/views/mountains.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }
    .view-dusk {
        background-image: url("../images/timeline/views/dusk.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.window-top {
    height: clamp(68px,6.75vw,136px);
    width: 100%;
    position: absolute;
    top: 0px;
    border-radius: 5px;
    overflow: hidden;
    outline: #8B929D solid;
    outline-width: clamp(5px,0.3vw,8px);
}

.window-bottom {
    height: clamp(68px,6.75vw,136px);
    width: 100%;
    position: absolute;
    top: clamp(7.2rem,7.2vw,14.4rem);
    border-radius: 5px;
    overflow: hidden;
    outline: #8B929D solid;
    outline-width: clamp(5px,0.3vw,8px);
}