:root {
  --carousel-button-size: 4.8rem;
}

.play-section-block {
  $self: &;
  
  &__content {
    position: relative;
    z-index: 2;
  }

  &__header {
    display: flex;
    @include container;
  }

  // Show hidden buttons on mobile
&__arrow--mobile {
  &#{$self}__arrow--prev, &#{$self}__arrow--next {
    position: absolute;
    height: 100%;
    width: 60px;
    top: 0;
    z-index: 1;
    transition: var(--transition-base);
    display: flex;
    justify-content: center;
    align-items: center;
    
    .icon {
      opacity: 0;
      transition: var(--transition-base);
    }
    
    &:focus-visible {
      outline: var(--border-small) solid var(--white);
      
      .icon {
        opacity: 1;
      }
    }
  }

  &#{$self}__arrow--prev {
    left: 0;
  }

  &#{$self}__arrow--next {
    right: 0;
  }

  @media(min-width: $lg) {
    display: none;
  }
}

  &__arrows {
    display: none;
    
    // Only show icon arrows on desktop
    @media(min-width: $lg) {
      display: flex;
      margin-top: -6px; // Margin-top is to align with the heading

      #{$self}__arrow--desktop {
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--carousel-button-size);
        width: var(--carousel-button-size);
        position: relative;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          border: 2px solid white;
          border-radius: 50%;
          height: var(--carousel-button-size);
          width: var(--carousel-button-size);
          transition: var(--transition-base);
        }

        &:first-of-type {
          margin-right: var(--spacing-xs);
        }

        &:hover,
        &:focus-visible {
          &:before {
            background-color: var(--white);
          }

          span::before {
            @include icon-color(var(--black));
          }
        }

        &:focus-visible {
          &::before {
            outline: var(--border-small) solid var(--white);
            outline-offset: var(--border-small);
          }
        }

        span {
          height: 2.3rem;
          width: 2.3rem;

          &:before {
            @include icon-size(2.3rem);
            transition: var(--transition-base);
          }
        }
      }
    }
  }
  
  &__carousel {
    padding-left: var(--spacing-xs);

    @media(min-width: $sm) {
      padding-left: var(--spacing-small);
    }

    @media(min-width: $md) {
      padding-left: var(--spacing-medium);
    }

    @media(min-width: $lg) {
      padding-left: var(--spacing-large);
    }
    
    @media(min-width: 1360px) {
      padding-left: 0;
    }
  }

  &__slide.splide__slide {
    position: relative;
    margin-right: var(--spacing-base);
    
    &:first-of-type {
      margin-left: 0;

      @media(min-width: 1360px) {
        margin-left: calc((100vw - (var(--max-width-content) + var(--spacing-xl) * 2)) / 2 + var(--spacing-large));
      }
      
    }

    @media(min-width: $md) {
      margin-right: var(--spacing-small);
    }

    @media(min-width: $lg) {
      margin-right: var(--spacing-medium);
    }
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .play-section-block {
    &__arrow {
      &:before {
        border-color: var(--black);
      }

      &:hover,
      &:focus-visible,
      &.is-active {
        &:before {
          background-color: var(--black);
        }

        span::before {
          @include icon-color(var(--white));
        }
      }

      &:focus-visible {
        outline-color: var(--black) !important;
      }

      span::before {
        @include icon-color(var(--black));
      }
    }
  }
}