.tag-container {
  @include horizontal-list;
  gap: var(--spacing-base);
}

.tag {
  border-radius: var(--border-radius-medium);
  border: var(--border-xs) solid var(--white);
  padding: calc(var(--spacing-base) / 2) var(--spacing-small);

  &--small {
    border-radius: var(--border-radius-xs);
    padding: 0.4rem 0.6rem;
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-small);
    text-transform: uppercase;
  }
}
