﻿.styleguide-page {
  &__horizontal-list {
    @include horizontal-list;
    width: 100%;
    justify-content: flex-start;
    gap: var(--spacing-small);
  }
}

.styleguide-block {
  &__content {
    width: 100%;
  }
}
