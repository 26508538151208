﻿.accordion-block {
  $self: &;
  
  &__content {
    width: 100%;
    align-self: flex-start;
    border-radius: var(--border-radius-large);
    background-color: rgba(255, 255, 255, 0.1);
    
    &[open] {
      background-color: var(--green-blue-20);
      color: var(--black);
      
      #{$self}__toggle-button {
        background-color: var(--white);
        
        &::before {
          @include icon-color(var(--black));
          @include icon-image(url("../images/icons/icon-minus.svg"));
        }
      }

      #{$self}__title {
        margin-bottom: var(--spacing-base);
      }

      #{$self}__panel {
        opacity: 1;
        animation: sweep 0.5s ease-in-out;
      }
    }
  }
  
  &__title {
    padding: var(--spacing-small);
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    font-family: var(--font-family-futura-medium);
    font-size: var(--font-size-large);
    list-style: none;
    cursor: pointer;
    transition: margin 150ms ease-out;

    &::-webkit-details-marker {
      display: none;
    }
    
    &:focus-visible {
      border-radius: var(--border-radius-large);
      outline: var(--border-small) solid var(--white);
    }
  }
  
  &__toggle-button {
    @include icon-plate(calc(var(--spacing-base) * 3), calc(var(--spacing-base) * 3), $is-circle: true);
    display: block;
    position: relative;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    
    &::before {
      @include icon($icon-size-small, $image: url("../images/icons/icon-plus.svg")); 
      @include center-absolute($icon-size-small, $icon-size-small);
      transition: transform 0.3s ease-in-out;
    }
  }
  
  &__title-text {
    flex: 1;
  }

  &__panel {
    padding: var(--spacing-small) var(--spacing-medium) var(--spacing-medium);
    opacity: 0;
    transition: opacity 150ms ease-out;
  }

  &__text {
    *:last-child {
      margin-bottom: 0;
    }
  }
  
  &__call-to-action {
    .button {
      @include button-color(var(--green-blue), var(--dark-green-blue), var(--white));
    }
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .accordion-block {
    &__content {
      background-color: rgba(23, 51, 58, 0.1);
      color: var(--black);
    }

    &__title:focus-visible {
      outline-color: var(--black);
    }

    &__toggle-button {
      &::before {
        @include icon-color(var(--black));
      }
    }
  }
}

@keyframes sweep {
  0%    {opacity: 0; transform: translateY(-10px)}
  100%  {opacity: 1; transform: translateY(0)}
}

.section-block--bg-white .accordion-block__content[open] {
  background-color: var(--green-blue-20);
}

.section-block--bg-light-green-blue .accordion-block__content[open] {
  background-color: var(--green-blue-50);
}

.accordion-block.content-area-item--full {
  .accordion-block__content {
    max-width: var(--max-width-box);
  }
}
