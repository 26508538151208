@mixin site-page-partial-color($bg-color, $text-color) {
  color: $text-color;

  .site-page-partial__text-container {
    background-color: $bg-color;
  }

  .site-page-partial__category-item {
    border-color: $text-color;
  }
  
  .site-page-partial__heading-icon::before {
    @include icon-color($text-color);
  }

  .site-page-partial__link {
    color: $text-color;

    &:focus-visible {
      @include focus-frame($text-color);
    }
  }

  .site-page-partial__arrow-icon::before {
    @include icon-color($text-color);
  }
}

@mixin dark-green-blue-color-theme {
  .site-page-partial {
    &__content {
      @include site-page-partial-color(var(--dark-green-blue), var(--white));
    }
    
    &__date-field {
      background-color: var(--green-blue-20);
      color: var(--black);
    }
    
    &__date-field-icon::before {
      @include icon-color(var(--black));
    }
    
    &__no-image-container {
      background-color: var(--green-blue);
    }
    
    &__train-icon::before {
      @include icon-color(var(--green-blue-75));
    }
  }


}

.site-page-partial {
  $self: &;
  
  &__content {
    @include site-page-partial-color(var(--green-blue-20), var(--black));
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    overflow: hidden;
    border-radius: var(--border-radius-large);
    box-shadow: 5px 5px 10px 0 rgba(0,0,0,0.25);
    cursor: pointer;
    
    &:hover {
      #{$self}__arrow-icon {
        margin-left: 0.6rem;
      }

      #{$self}__arrow-icon.icon--arrow-down {
        margin-left: 0;
        &::before {
          margin-top: 0.6rem;
        }
      }
      
      #{$self}__image {
        transform: scale(1.1);
      }
    }

    &--has-date-field {
      #{$self}__no-image-container {
        background-color: var(--green-blue);
      }

      #{$self}__train-icon::before {
        @include icon-color(var(--green-blue-75));
      }
    }
  }
  
  &__image-container,
  &__no-image-container {
    @include image-container-cover;
    height: 24.5rem;
  }

  &__image-container, &__no-image-container {
    & + #{$self}__text-container {
      padding-top: 0;
    }
  }

  &__no-image-container {
    background-color: var(--dark-green-blue);
  }
  
  &__image {
    transition: transform 0.4s ease-in-out;
  }
  
  &__train-icon {
    @include icon-plate-size(9.6rem, 9.6rem);
    
    &::before {
      @include icon-size(9.6rem);
      @include icon-color(var(--green-blue));
    }
  }
  
  &__text-container {
    @include flex-container-column;
    align-items: stretch;
    flex: 1;
    height: 100%;
    padding: var(--spacing-xs) var(--spacing-small) 0;
  }
  
  &__date-field {
    display: flex;
    align-items: center;
    gap: var(--spacing-base);
    width: calc(100% + var(--spacing-small) + var(--spacing-small));
    margin-left: calc(var(--spacing-small) * -1);
    padding: var(--spacing-base);
    padding-left: var(--spacing-small);
    padding-right: var(--spacing-small);
    background-color: var(--dark-green-blue);
    color: var(--white);
    font-size: var(--font-size-small);
    font-family: var(--font-family-futura-medium);
    line-height: var(--line-height-small);
    
    &-icon {
      @include icon-plate-size(1.8rem, 1.8rem);
      
      &::before {
        @include icon-size(1.8rem);
        @include icon-color(var(--white));
      }
    }
    
    &-text {
      flex: 1;
    }
  }
  
  &__category-list {
    justify-content: flex-start;
    padding: var(--spacing-small) 0;

    & + .heading {
      margin-top: 0;
    }
  }
  
  &__category-item {
    border-color: var(--black);
  }
  
  &__heading,
  &__heading:first-child {
    margin: calc(var(--spacing-base) * 2.5) 0 var(--margin-heading-small-5);
  }
  
  &__link {
    text-decoration: none;
  }
  
  &__excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
  }

  &__date {
    font-size: var(--font-size-small);
    margin-top: var(--margin-small-text-10);
    
    time {
      font-family: var(--font-family-futura-medium);
    }
  }
  
  &__arrow-icon-container {
    padding-bottom: var(--spacing-medium);
    margin-top: auto;
  }
  
  &__arrow-icon {
    @include icon-plate-size(3rem, 3rem);
    margin-top: calc(var(--spacing-small) - 0.4rem);
    transition: var(--transition-base);
    
    &::before {
      @include icon-size(3rem);
      transition: var(--transition-base);
    }
  }

  &--dark-green-blue {
    @include dark-green-blue-color-theme;
  }
  
  &.content-area-item {
    &--full {
      @media(min-width: $lg) {
        #{$self}__image-container {
          height: 27.5rem;
        }

        #{$self}__excerpt {
          max-width: 80rem;
        }
      }
    }
  }
}

// Site page partial color based on section block
.section-block--site-page-partial-color-black {
  .site-page-partial__content {
      @include site-page-partial-color(var(--black), var(--white));
  }
  
  &.section-block--bg-black {
    .site-page-partial__text-container {
        padding-right: 0.4rem;
        padding-left: 0.4rem;
    }
    
    &.section-block--bg-smoke {
      .site-page-partial__text-container {
        padding-right: var(--spacing-small);
        padding-left: var(--spacing-small);
      }
    }
  }
}

.section-block--site-page-partial-color-dark-green-blue {
  @include dark-green-blue-color-theme;
}

.section-block--bg-black.section-block--bg-smoke {
  .site-page-partial {
    &__no-image-container {
      background-color: var(--green-blue);
    }
  
    &__train-icon::before {
      @include icon-color(var(--green-blue-75));
    }
  }
}
