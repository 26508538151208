﻿.countdown-block {
  text-align: center;

  &__content {
    width: 100%;
  }

  &__timer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    column-gap: var(--spacing-small);
    margin: 0 auto;
    font-family: var(--font-family-futura-medium);
    max-width: 56rem;

    @media(min-width: $md) {
      column-gap: var(--spacing-medium);
    }
  }

  &__timer-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.6rem;

    @media(min-width: $sm) {
      column-gap: var(--spacing-base);
      row-gap: var(--spacing-xs);
    }

    @media(min-width: $md) {
      row-gap: var(--spacing-small);
    }
  }

  &__label {
    grid-column: 1 / span 2;
    font-size: var(--font-size-xxs);
    text-transform: uppercase;

    @media(min-width: $sm) {
      font-size: var(--font-size-xs);
    }

    @media(min-width: $md) {
      font-size: var(--font-size-small);
    }
  }
}

.flip-card {
  position: relative;
  display: block;
  height: 4.5rem;
  background-color: #303030;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-xs);
  font-size: var(--font-size-large);
  line-height: 4.5rem;

  @media(min-width: $sm) {
    height: 7rem;
    font-size: 4rem;
    line-height: 7rem;
  }

  @media(min-width: $md) {
    height: 8rem;
    font-size: 5.4rem;
    line-height: 8rem;
  }

  & > span {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  &__top,
  &__bottom-back {
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
  }
  
  &__top {
    z-index: 3;
    background-color: #202020;
    transform-origin: 50% 100%;
    border-top-right-radius: var(--border-radius-xs);
    border-top-left-radius: var(--border-radius-xs);
    transform: perspective(2em) rotateX(0);
    animation: flip-top 0.6s ease;
  }
  
  &__bottom {
    z-index: 1;
    height: 100%;
    
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: rgba(0, 0, 0, .02);
    }
  }
  
  &__bottom-back {
    z-index: 2;
    top: 0;
    height: 50%;
    overflow: hidden;
    background-color: #202020;
    border-top-right-radius: var(--border-radius-xs);
    border-top-left-radius: var(--border-radius-xs);

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
    }
  }
  
  &__top,
  &__top-back {
    height: 50%;
    overflow: hidden;
    backface-visibility: hidden;
  }
  
  &__top-back {
    z-index: 4;
    bottom: 0;
    background-color: #303030;
    transform-origin: 50% 0;
    transform: perspective(2em) rotateX(180deg);
    border-bottom-right-radius: var(--border-radius-xs);
    border-bottom-left-radius: var(--border-radius-xs);
    animation: flip-top-back 0.6s ease;

    span {
      position: absolute;
      top: -100%;
      left: 0;
      right: 0;
      margin: auto;
      height: 100%;
    }
  }
}

@keyframes flip-top {
  100% {
    transform: perspective(2.6em) rotateX(-180deg);
  }
}

@keyframes flip-top-back {
  100% {
    transform: perspective(2.6em) rotateX(0deg);
  }
}

.section-block--bg-white,
.section-block--bg-light-green-blue {
  .countdown-block__label {
    color: var(--black);
  }
}


