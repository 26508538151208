﻿.illustration {
    background-size: clamp(13rem, 13.5vw, 25rem);

    &--old-suitcase {
        background-image: url("../images/timeline/illustrations/old-suitcase.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
    }

    &--seat-1800 {
        background-image: url("../images/timeline/illustrations/seat-1800.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
    }

    &--seat-1900 {
        background-image: url("../images/timeline/illustrations/seat-1900.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: clamp(11rem, 11vw, 21rem);
    }

    &--seat-2000 {
        background-image: url("../images/timeline/illustrations/seat-2000.svg");
        background-repeat: no-repeat;
        background-position: bottom left;
    }
}
