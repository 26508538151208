@mixin clear-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  & > *,
  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

@mixin horizontal-list {
  @include clear-list;
  @include flex-container-row;
  align-items: center;
}
