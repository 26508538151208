ol,
ul {
  margin-bottom: var(--margin-body-text);
  padding-left: 2em;
  
  li {
    margin-top: var(--margin-list-item);
    
    &:first-child {
      margin-top: 0;
    }
  }

  ol,
  ul {
    margin-bottom: 0;
    
    li:first-child {
      margin-top: var(--margin-list-item);
    }

    li:last-child {
      margin-bottom: var(--margin-list-item-bottom);
    }
  }
}

.info-list {
  $self: &;
  
  display: grid;
  grid-template-columns: max-content auto;
  gap: var(--margin-list-item);
  
  &__term {
    font-family: var(--font-family-futura-medium);
  }
  
  &--space-between {
    #{$self}__details {
      margin-left: auto;
    }
  }
}

@media (max-width: $sm) {
  .contact-block {
    .info-list {
      display: block;
      &__details {
        margin-bottom: 10px;
      }
    }
  }
}
