﻿.sitemap-block {
  &__content {
    width: 100%;
  }
  
  &__menu,
  &__submenu {
    @include clear-list;
  }
  
  &__submenu {
    position: relative;
    padding: calc(0.75rem / 2) 0 var(--spacing-small) calc(var(--spacing-base) * 2.5);
    
    &:last-child {
      padding-bottom: 0;
    }
  }
  
  &__menu-item {
    display: block;
    width: 100%;
  }
  
  &__submenu-item {
    position: relative;
    
    &::before,
    &::after {
      @include pseudo-block;
      top: 0;
      left: calc(var(--spacing-xs) * -1);
      width: var(--spacing-base);
      border-left: var(--border-small) solid var(--black-50);
    }
    
    &::before {
      height: calc(var(--click-surface-min-size) / 2);
      border-bottom: var(--border-small) solid var(--black-50);
    }
    
    &::after {
      height: 100%;
    }
    
    &:last-child::after {
      display: none;
    }
  }
  
  &__menu-link,
  &__submenu-link {
    display: flex;
    align-items: center;
    height: auto;
    min-height: var(--click-surface-min-size);
    padding: 0.7rem 0;
    position: relative;
    color: var(--white);
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
    
    &:focus-visible {
      outline-color: var(--white);
    }
  }
  
  &__menu-link {
    font-size: var(--font-size-heading-medium);
    line-height: var(--line-height-heading-medium);
  }
}

.section-block--bg-white,
.section-block--bg-light-green-blue {
  .sitemap-block {
    &__menu-link,
    &__submenu-link {
      color: var(--black);
  
      &:focus-visible {
        outline-color: var(--black);
      }
    }
  }
}
