.social-media-link {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-small);
  
  &__icon {
    @include icon-plate-size($icon-size-small, $icon-size-small);
    
    &::before {
      @include icon-size($icon-size-small);
    }
  }
  
  &__text {
    display: block;
  }
}