﻿.submenu {
  $self: &;
  
  @include clear-list;
  padding: calc(var(--spacing-xs) / 2) 0 var(--spacing-small) var(--spacing-base);
  display: none;
  
  &--open {
    display: block;
  }
  
  &__item-container {
    @include menu-item-container;
  }

  &__item--selected {
      & > #{$self}__item-container #{$self}__link::after {
        width: 100%;
      }
  }
  
  &__link {
    @include menu-link;
    padding: 0.75rem 0;
  }
}

.menu__item:last-child,
.submenu__item:last-child {
  & > .submenu {
    padding-bottom: 0;
  }
}
