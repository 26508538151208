.dynamic-list-block {
  &__content {
    width: 100%;
    opacity: 1;
    transition: var(--transition-base);
    position: relative;
    z-index: 2;
  }

  &__content--hidden {
    opacity: 0;
  }
  
  &__button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--spacing-xl);
  }

  &__pages {
    @include clear-list;
  }

  &__page {
    transition: var(--transition-base);
    opacity: 1
  }

  &__page--fade-in {
    opacity: 0;
  }
}
