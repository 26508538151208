﻿.modal {
  width: 100vw;
  height: 100vh;
  background: var(--black) none;
  color: var(--white);
  overflow-y: auto;
  overflow-x: hidden ;
  position: fixed;
  padding: 20px;
  text-align: left;
  white-space: normal;
  left: 0;
  top: 0;
  z-index: 200;
  
  &-backdrop {
    background-color: var(--gray-alpha);
    content: ' '; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 150;
  }

  @media (min-width: 992px) {
    max-height: calc(95vh - 60px);
    min-height: unset;
    overflow-y: auto;
    width: 70vw;
    height: auto;
    left: 50%;
    top: 50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
  }

  &__close {
    border: none;
    background: none;
    margin-left: auto;
  }

  &--scroll {
    overflow-y: scroll;
  }
}

.disable-scroll{
  overflow: hidden;
}
