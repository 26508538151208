﻿.tool-menu {
  $self: &;
  --tool-menu-padding-vertical: 0.6rem;
  
  @include horizontal-list;
  justify-content: flex-start;
  gap: var(--spacing-xs) calc(var(--spacing-base) * 3);
  padding: var(--tool-menu-padding-vertical) 0;

  @media(min-width: $header-desktop-breakpoint) {
    justify-content: flex-end;
  }
  
  &__item {
    &--has-dropdown {
      position: relative;
      
      #{$self}__link {
        padding-right: calc((var(--spacing-base) / 2) + 0.8rem);
        position: relative;
  
        &::after {
          @include icon(0.8rem, $image: url("../images/icons/icon-arrow-head-down.svg"));
          right: 0;
          top: 50%;
          margin-top: calc((0.8rem / 2) * -1);
        }
      }
    }
    
    &--language {
      width: 100%;

      @media(min-width: $header-desktop-breakpoint) {
        width: auto;
      }
    }
  }
  
  &__link {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing-base) / 2);
    font-size: var(--font-size-xs);
    line-height: var(--line-height-medium);
    color: var(--white);
    text-decoration: none;

    @media(min-width: $header-desktop-breakpoint) {
      font-size: 1.6rem;
    }
    
    &:hover {
      text-decoration: underline;
    }
    
    &:focus-visible {
      @include focus-frame(var(--white));
    }
  }
  
  &__icon {
    @include icon-plate-size($icon-size-xs, $icon-size-xs);
    
    &::before {
      @include icon-size($icon-size-xs);
    }
  }
  
  &__dropdown {
    display: none;
    
    &--open {
      display: block;
    }
  }
}
