﻿:root {
  --image-height: 215px;
  --image-height-sm: 310px;
  --image-height-md: 360px;
  --image-height-lg: 416px;
  --carousel-button-size: var(--click-surface-min-size);
  --carousel-button-size-lg: 4.8rem;
  --inner-button-size: 2.4rem;
}

.carousel-block {
  $self: &;
  
  &__content {
    width: 100%;
  }
  
  &__heading {
    @include container;
    text-align: center;
  }
  
  &__carousel.splide {
    margin: var(--spacing-centered);
    width: 100%;

    &.is-focus-in .splide__arrow:focus,
    &.is-focus-in .splide__pagination__page:focus {
      outline: none;
    }
  }
  
  &__slides-container {
    position: relative;
  }
  
  &__track {
    @media(min-width: $xxl) {
      position: relative;
      
      &::before,
      &::after {
        @include pseudo-block;
        width: 12rem;
        height: 100%;
        z-index: 1;
        top: 0;
      }
      
      &::before {
        left: 0;
        background: linear-gradient(90deg, var(--black) 0%, transparent 100%);
      }
      
      &::after {
        right: 0;
        background: linear-gradient(90deg, transparent 0%, var(--black) 100%);
      }
    }
  }
  
  &__slide.splide__slide {
    position: relative;
    margin-right: var(--spacing-base);

    @media(min-width: $md) {
      margin-right: var(--spacing-small);
    }

    @media(min-width: $lg) {
      margin-right: var(--spacing-medium);
    }
    
    &.is-active {
      #{$self}__caption {
        @media(min-width: $lg) {
          display: block;
        }
      }
    }
  }
  
  &__image-container {
    @include image-container-cover;
    height: var(--image-height);

    @media(min-width: $sm) {
      height: var(--image-height-sm);
    }

    @media(min-width: $md) {
      height: var(--image-height-md);
    }

    @media(min-width: $lg) {
      height: var(--image-height-lg);
    }
  }
  
  &__image {
    @include image-border-radius;
    border: var(--border-small) solid transparent;
    padding: var(--border-small);
    cursor: pointer;
  }
  
  &__caption {
    display: none;
  }
  
  &__description {
    margin-top: calc(var(--spacing-base) - (var(--border-small) * 2));
    text-align: center;
  }
  
  &__byline {
    right: calc(var(--border-small) * 2);
    bottom: calc(100% + (var(--border-small) * 2));
    padding-right: var(--spacing-medium);
    border-bottom-right-radius: calc(var(--border-radius-xl) - (var(--border-small) * 2));
    max-width: calc(100% - var(--spacing-small) - (var(--border-small) * 2));

    @media(min-width: $md) {
      max-width: calc(100% - var(--spacing-medium) - (var(--border-small) * 2));
    }
  }
  
  &__arrow.splide__arrow {
    width: var(--carousel-button-size);
    height: var(--carousel-button-size);
    background-color: transparent;
    border-radius: 0;
    top: calc((var(--image-height) - var(--carousel-button-size)) / 2);
    opacity: 1;
    transform: none;
    
    &:before {
      transition: var(--transition-base);
    }

    @media(min-width: $sm) {
      top: calc((var(--image-height-sm) - var(--carousel-button-size)) / 2);
    }

    @media(min-width: $md) {
      top: calc((var(--image-height-md) - var(--carousel-button-size)) / 2);
    }

    @media(min-width: $lg) {
      width: var(--carousel-button-size-lg);
      height: var(--carousel-button-size-lg);
      top: calc((var(--image-height-lg) - var(--carousel-button-size-lg)) / 2);
    }

    &:hover,
    &:focus-visible {
      &::before {
        background-color: var(--white);
      }
      
      svg {
        fill: var(--black);
      }
    }
    
    &:focus-visible {
      &::before {
        outline: var(--border-small) solid var(--white);
        outline-offset: var(--border-small);
      }
    }
    
    &::before {
      @include pseudo-block;
      @include center-absolute(var(--inner-button-size), var(--inner-button-size));
      width: var(--inner-button-size);
      height: var(--inner-button-size);
      background-color: var(--black);
      border: var(--border-xs) solid var(--white);
      border-radius: var(--border-radius-round);

      @media(min-width: $md) {
        @include fill-container-absolute;
        border-width: var(--border-small);
      }
    }
    
    &:hover:not(:disabled) {
      opacity: 1;
    }
    
    &--next {
      right: 0;

      @media(min-width: $sm) {
        right: 1.3rem;
      }

      @media(min-width: $md) {
        right: var(--spacing-medium);
      }

      @media(min-width: $xxl) {
        right: var(--spacing-large);
      }
    }
    
    &--prev {
      left: 0;

      @media(min-width: $sm) {
        left: 1.3rem;
      }

      @media(min-width: $md) {
        left: var(--spacing-medium);
      }
    }
    
    svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: var(--white);
      position: absolute;
      z-index: 1;
      transition: var(--transition-base);

      @media(min-width: $md) {
        width: 2rem;
        height: 2rem;
      }

      @media(min-width: $md) {
        width: 2.3rem;
        height: 2.3rem;
      }
    }
  }

  &__pagination.splide__pagination {
    @include container;
    gap: var(--spacing-base);
    margin-top: var(--spacing-small);
    position: static;

    @media(min-width: $md) {
      margin-top: calc(var(--spacing-small) + var(--spacing-base));
    }
  }
  
  &__pagination-page.splide__pagination__page {
    display: block;
    width: 2.8rem;
    height: 1.8rem;
    margin: 0;
    background-color: transparent;
    border: var(--border-small) solid var(--white);
    border-radius: var(--border-radius-xs);
    opacity: 1;
    transition: var(--transition-base);

    @media(min-width: $md) {
      width: 3rem;
      height: 2rem;
    }

    &:hover,
    &:focus-visible,
    &.is-active {
      background-color: var(--white);
    }

    &:focus-visible {
      outline: var(--border-small) solid var(--white) !important;
      outline-offset: var(--border-small) !important;
    }
    
    &.is-active {
      transform: none;
    }
  }

  &__open-modal-button {
    &:focus,
    &:focus-visible {
      clip: rect(0,0,0,0);
      border: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;

      & + #{$self}__figure #{$self}__image {
        border-color: var(--orange);
      }
    }
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .carousel-block {
    &__pagination-page.splide__pagination__page {
      border-color: var(--black);

      &:hover,
      &:focus-visible,
      &.is-active {
        background-color: var(--black);
      }

      &:focus-visible {
        outline-color: var(--black) !important;
      }
    }

    &__open-modal-button {
      &:focus,
      &:focus-visible {
        & + .carousel-block__figure .carousel-block__image {
          border-color: var(--black);
        }
      }
    }
  }
}

.section-block--bg-white {
  .carousel-block {
    &__track {
      @media(min-width: $xxl) {
        &::before {
          background: linear-gradient(90deg, var(--white) 0%, transparent 100%);
        }

        &::after {
          background: linear-gradient(90deg, transparent 0%, var(--white) 100%);
        }
      }
    }
  }
}

.section-block--bg-light-green-blue {
  .carousel-block {
    &__track {
      @media(min-width: $xxl) {
        &::before {
          background: linear-gradient(90deg, var(--green-blue-20) 0%, transparent 100%);
        }

        &::after {
          background: linear-gradient(90deg, transparent 0%, var(--green-blue-20) 100%);
        }
      }
    }
  }
}
