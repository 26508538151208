.content-area {
  @include flex-container-row;
  row-gap: var(--spacing-medium);
  width: 100%;
  position: relative;

  @media(min-width: $sm) {
    justify-content: space-between;
  }

  @media(min-width: $xl) {
    row-gap: var(--spacing-large);
  }
  
  &--thirds,
  &--halves {
    row-gap: calc(var(--spacing-base) * 3);
    
    @media(min-width: $sm) {
      justify-content: flex-start;
    }

    @media(min-width: $md) {
      column-gap: 2.586%;
    }
  }
  
  &--thirds {
    @media(min-width: $lg) {
      column-gap: 2.1545%;
    }
    
    .content-area-item--third {
      @media(min-width: $md) {
        width: 48.707%;
      }
      
      @media(min-width: $lg) {
        width: 31.897%;
      }
    }
  }

  &--halves {
    .content-area-item--half {
      @media(min-width: $md) {
        width: 48.707%;
      }
    }
  }

  &--next-to-sidebar {
    @media(min-width: $lg) {
      max-width: calc(var(--max-full-width-text) + var(--spacing-large));
      flex: 1;
      
      .content-area-item {
        justify-content: flex-start;
        
        &--third,
        &--half {
          width: 48.685%;
        }
  
        &--twothirds {
          width: 100%;
        }
      }
    }
  }
}

.content-area-item {
  @include content-area-item;
}
