﻿.image-modal {
  $self: &;

  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100vh;
  display: none;
  position: fixed;
  color: var(--white);

  &[open] {
    @include full-screen-modal;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.95);
  }

  &__content {
    --margin-top: calc(var(--spacing-small) + var(--carousel-button-size) + var(--spacing-xs));
    --margin-top-md: calc(var(--spacing-small) + var(--carousel-button-size) + var(--spacing-small));
    --margin-top-lg: calc(var(--spacing-small) + var(--carousel-button-size-lg) + var(--spacing-small));

    width: 100%;
    max-width: calc(var(--max-width-content-wide) + (var(--spacing-small) * 2));
    max-height: calc(100vh - var(--margin-top) - var(--spacing-small));
    padding: 0 var(--spacing-xs);
    margin: var(--margin-top) auto var(--spacing-small);
    position: relative;
    display: flex;
    justify-content: center;

    @media(min-width: $sm) {
      padding-right: var(--spacing-small);
      padding-left: var(--spacing-small);
    }

    @media(min-width: $md) {
      margin-top: var(--margin-top-md);
      max-height: calc(100vh - var(--margin-top-md) - var(--spacing-small));
    }

    @media(min-width: $lg) {
      max-height: calc(100vh - var(--margin-top-lg) - var(--spacing-small));
      margin-top: var(--margin-top-lg);
    }

  }

  &__close-button {
    @include flex-center-child;
    width: var(--carousel-button-size);
    height: var(--carousel-button-size);
    position: absolute;
    top: calc((var(--carousel-button-size) + var(--spacing-xs)) * -1);
    right: var(--spacing-base);
    background-color: transparent;
    transition: var(--transition-base);
    z-index: 1;

    @media(min-width: $md) {
      top: calc((var(--carousel-button-size) + var(--spacing-small)) * -1);
      right: var(--spacing-small);
    }

    @media(min-width: $lg) {
      width: var(--carousel-button-size-lg);
      height: var(--carousel-button-size-lg);
      top: calc((var(--carousel-button-size-lg) + var(--spacing-small)) * -1);
    }

    &:hover,
    &:focus-visible {
      #{$self}__close-icon {
        background-color: var(--white);

        &::before {
          @include icon-color(var(--black));
        }
      }
    }

    &:focus-visible {
      #{$self}__close-icon {
        outline: var(--border-small) solid var(--white);
        outline-offset: var(--border-small);
      }
    }
  }

  &__close-icon {
    @include icon-plate(var(--inner-button-size), var(--inner-button-size), $is-circle: true);
    border: var(--border-small) solid var(--white);


    @media(min-width: $md) {
      @include icon-plate-size(var(--carousel-button-size), var(--carousel-button-size));
    }

    @media(min-width: $lg) {
      @include icon-plate-size(var(--carousel-button-size-lg), var(--carousel-button-size-lg));
    }

    &::before {
      @include icon-size(1.4rem);
      @include center-absolute(1.4rem, 1.4rem);

      @media(min-width: $md) {
        @include icon-size(2rem);
        @include center-absolute(2rem, 2rem);
      }

      @media(min-width: $lg) {
        @include icon-size($icon-size-medium);
        @include center-absolute($icon-size-medium, $icon-size-medium);
      }
    }
  }

  &__image {
    @include image-border-radius;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: var(--spacing-centered);
  }

  &__caption {
    width: 100%;
    margin-top: 0;
    padding-top: var(--spacing-small);
    text-align: center;
  }

  &__byline {
    bottom: 100%;
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .image-modal {
    &__caption {
      color: var(--white);
    }
  }
}
