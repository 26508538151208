.breadcrumbs {
  @include container;
  padding-top: var(--spacing-large);
  padding-bottom: var(--spacing-small);

  @media(min-width: $md) {
    padding-top: var(--spacing-xl);
  }

  @media(min-width: $xl) {
    padding-top: var(--spacing-xxl);
  }

  &__list {
    @include horizontal-list;
    gap: 0.6rem;
  }

  &__item {
    display: block;
    position: relative;
    font-size: var(--font-size-small);

    & + & {
      padding-left: calc(0.9rem + 0.6rem);
      
      &::before {
        @include icon(0.9rem, var(--orange), url("../images/icons/icon-arrow-head-right.svg"));
        margin-top: calc((0.9rem / 2) * -1);
        top: 50%;
        left: 0;
      }
    }

    &.breadcrumbs__item--active {
      text-decoration: underline;
      
      &::before {
        @include icon-color(var(--white));
      }
    }
  }
}

.header + .main-content .page-header {
  .breadcrumbs {
    padding-top: var(--spacing-small);
  }
  
  &__image-container + .breadcrumbs {
    padding-top: var(--spacing-medium);
  }
}

.page-header__image-container + .breadcrumbs {
  padding-top: var(--spacing-medium);
}
