﻿.language-menu {
  $self: &;

  margin: var(--spacing-small) 0 calc(var(--spacing-large) - 0.6rem - var(--spacing-small));

  @media(min-width: $header-desktop-breakpoint) {
    position: absolute;
    top: calc(2.4rem + 0.6rem);
    left: calc((var(--spacing-base) * 3) * -1);
    padding: var(--spacing-small);
    margin: 0;
    background-color: var(--black);
    border-radius: var(--border-radius-large);
    box-shadow: 0.5rem 0.5rem 1rem 0 rgba(0,0,0,0.25);
    z-index: 6;
    white-space: nowrap;
  }
  
  &__list {
    @include clear-list;
  }
  
  &__item {
    margin-bottom: var(--spacing-base);
    
    &--selected {
      #{$self}__link {
        color: var(--orange);
        
        &::before,
        &:hover::before {
          background-color: var(--orange);
          box-shadow: 0 0 0 var(--border-small) var(--orange);
        }
      }
    }
  }
  
  &__link {
    position: relative;
    padding-left: calc(2.4rem + 0.6rem);
    color: var(--white);
    text-decoration: none;
    
    &::before {
      @include pseudo-block;
      top: 50%;
      left: var(--border-small);
      width: 2rem;
      height: 2rem;
      margin-top: calc((2rem / 2) * -1);
      background: var(--black);
      border: var(--border-medium) solid var(--black);
      border-radius: var(--border-radius-round);
      box-shadow: 0 0 0 var(--border-small) var(--white);
      transition: var(--transition-base);
    }
    
    &:hover {
      &::before {
        background-color: var(--white);
      }
    }
    
    &:focus-visible {
      outline-color: var(--white);
    }
  }
  
  .link {
    margin-top: var(--spacing-base);
  }
}
