﻿.calendar-block {
  
  &__section {

    &--mini-calendar {
      @media(max-width: $sm) {
        .heading--standalone {
          margin-bottom: 40px !important;
        }
      }

      @media(min-width: $md) {
        .heading--standalone {
          margin-bottom: 60px !important;
        }
      }
      
      .calendar-block {
        
        &__container {
          
          .calendar {
            
            .calendar__wrapper {
              
              .site-page-partial {

                &__text-container {
                  padding: 0px;
                }

                &__category-item {
                  border-color: white;
                }

                &__date-field {
                  background-color: var(--green-blue-20);
                  color: black;
                  padding-left: var(--spacing-small-medium);
                  padding-right: var(--spacing-small-medium);
                }

                &__date-field-icon::before {
                  background-color: black;
                }

                &__content {
                  background-color: black;
                  color: white;

                }
              }

              .site-page-partial__content .site-page-partial__text-container {
                background-color: black;
                color: white;
              }

              .site-page-partial__content .site-page-partial__link {
                color: white;
              }

              .site-page-partial__content .site-page-partial__arrow-icon::before {
                background-color: white;
              }
            }
          }
        }
      }
      
      .call-to-action {
        justify-content: center;
      }
    }
    
    .calendar-block {
      width: 100%;
      min-height: 933px;
      position: relative;
      z-index: 2;

      .section-block__container {
        .call-to-action {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }

      &__app {
        width: 100%;
        display: flex;
      }

      &__filter-heading {
        margin-top: 0;
      }

      &__container {
        margin-right: auto;
        margin-left: auto;
        max-width: 1200px;
        width: 100%;

        .calendar {
          display: flex;
          flex-flow: column;
          justify-content: center;
          opacity: 1;
          transition: opacity 0.5s ease-in-out;

          &--hidden {
            opacity: 0;
          }

          .calendar__wrapper {
            
            .calendar__container {
              @include clear-list;

              .calendar-event-item {
                &__date-string {
                  text-transform: uppercase;
                }
              }
            }
          }

          &__header-section {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: calc(3.8rem + (var(--border-small) * 2) + var(--spacing-base) + var(--border-xl));
            padding-bottom: var(--spacing-base);
            margin-bottom: var(--spacing-medium);
            border-bottom: var(--border-xl) solid var(--white);
            text-transform: capitalize;

            @media(min-width: $md) {
              padding-bottom: var(--spacing-small);
              margin-bottom: var(--spacing-large);
              min-height: calc(3.8rem + (var(--border-small) * 2) + var(--spacing-small) + var(--border-xl));
            }
          }

          &__current-month-heading {
            margin: 0;
          }

          &__month-button {
            position: absolute;
            top: calc(var(--border-small) * 2);
            display: flex;
            align-items: center;
            gap: var(--spacing-base);

            &--prev {
              left: calc(var(--border-small) * 2);
            }

            &--next {
              right: calc(var(--border-small) * 2);
            }

            &:hover,
            &:hover:focus-visible,
            &:active {
              .calendar__month-button-icon {
                background-color: var(--orange-50);
                border-color: var(--orange-50);
              }
            }

            &:focus-visible {
              .calendar__month-button-icon {
                border-radius: 50%;
                outline: var(--border-small) solid var(--orange);
                outline-offset: var(--border-small);
              }
            }

            &[disabled] {
              cursor: default;

              .calendar__month-button-icon {
                background-color: var(--black-50);

                &::before {
                  @include icon-color(var(--white));
                }
              }
            }
          }

          &__month-button-text {
            @include sr-only;
            color: var(--white);
            text-transform: capitalize;

            @media(min-width: $md) {
              &:not(:focus):not(.sr-only) {
                height: auto;
                margin: 0;
                position: static;
                width: auto;
              }
            }
          }

          &__month-button-icon {
            @include icon-plate(3.8rem, 3.8rem, var(--orange), true);
            transition: background-color var(--transition-timing) ease-in-out, color var(--transition-timing) ease-in-out;

            &::before {
              @include icon-color(var(--black));
              @include center-absolute($icon-size-medium, $icon-size-medium);
            }
          }
        }
      }
    }
  }
}
