﻿.main-navigation {
  $self: &;
  
  &__toggle-button {
    --short-stroke-length: 3rem;
    --space-between-strokes: 0.8rem;
    
    width: var(--click-surface-min-size);
    height: var(--click-surface-min-size);
    padding: 0.1rem 0.2rem;
    position: absolute;
    bottom: calc(var(--spacing-small) - 0.1rem);
    right: calc(var(--spacing-small) - 0.2rem);
    font-size: var(--font-size-xxs);
    line-height: 1;
    text-align: right;
    text-transform: uppercase;
    color: var(--white);

    @media(min-width: $header-desktop-breakpoint) {
      bottom: calc(var(--header-padding-bottom-desktop) - 0.1rem);
      right: calc(var(--spacing-medium) - 0.2rem);
    }

    @media(min-width: $xl) {
      right: calc(var(--spacing-large) - 0.2rem);
    }
    
    &:hover {
      #{$self}__toggle-icon-strokes {
        &::before {
          width: var(--header-toggle-button-width);
        }
      }
    }
    
    &:focus-visible {
      @include focus-frame(var(--white));
    }
    
    &--expanded {
      &:hover {
        #{$self}__toggle-icon-strokes {
          &::before {
            width: var(--short-stroke-length);
          }
        }
      }
      
      #{$self}__toggle-icon-strokes {
        background-color: transparent;
        
        &::before,
        &::after {
          top: 0;
        }
        
        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }
    }
  }
  
  &__toggle-icon {
    display: block;
    width: var(--header-toggle-button-width);
    height: calc((var(--border-small) * 3) + (var(--space-between-strokes) * 2));
    padding: var(--spacing-base) 0;
    margin-bottom: var(--space-between-strokes);
    
    &-strokes {
      @include border-radius-half-height(var(--border-small));
      display: block;
      width: 100%;
      height: var(--border-small);
      position: relative;
      background-color: var(--white);
      transition: var(--transition-base);
      
      &::before,
      &::after {
        @include pseudo-block;
        @include border-radius-half-height(var(--border-small));
        width: var(--short-stroke-length);
        height: var(--border-small);
        right: 0;
        background-color: var(--white);
        transition: var(--transition-base);
      }
      
      &::before {
        top: calc((var(--border-small) + var(--space-between-strokes)) * -1);
      }

      &::after {
        top: calc((var(--border-small) + var(--space-between-strokes)));
      }
    }
  }
  
  &__content {
    display: none;
    width: 100%;
    height: calc(100vh - var(--header-height-mobile));
    position: absolute;
    top: var(--header-height-mobile);
    left: 0;

    @media(min-width: $header-desktop-breakpoint) {
      height: calc(100vh - var(--header-height-desktop));
      top: var(--header-height-desktop);
    }
    
    &--open {
      display: block;
    }
    
  }
  
  &__backdrop {
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
    
    &--visible {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__items {
    width: 100%;
    height: 100%;
    max-width: 47rem;
    padding: var(--spacing-xs) 1.6rem var(--spacing-xxl);
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: -47rem;
    background-color: var(--black);
    scrollbar-color: rgba(255, 255, 255, 0.7) transparent;
    scrollbar-width: thin;
    transition: right 0.4s ease-in-out;
    
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.7);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    
    &--visible {
      right: 0;
    }
  }
  
  &__tool-menu,
  &__buy-ticket {
    @include main-navigation-block;
  }
  
  &__tool-menu {
    margin-bottom: var(--spacing-small);
  }
  
  &__buy-ticket {
    display: flex;
    margin-top: var(--spacing-large);
  }
}
