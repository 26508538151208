﻿.radio-group {
  @include form-group;
  
  .radio-button {
    @include check-element;
    
    &__input {
      border: var(--border-medium) solid var(--black);
      border-radius: var(--border-radius-round);
      
      &:checked,
      &:hover {
        background-color: var(--white);
        cursor: pointer;
        
        + .form-label {
          cursor: default;
        }
      }
    }
  }
}
