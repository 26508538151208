a {
  color: var(--orange);

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }

  &:focus-visible {
    @include focus-frame;
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  a {
    color: var(--dark-orange-darken);

    &:focus-visible {
      outline-color: var(--dark-orange-darken);
    }
  }
}

.section-block--bg-dark-orange {
  a {
    color: var(--clear-white);

    &:focus-visible {
      outline-color: var(--clear-white);
    }
  }
}

// Button link
.link {
  --link-icon-size: 2rem;
  --link-padding-vertical: 0.8rem;
  --link-padding-horizontal: 0.6rem;

  display: block;
  position: relative;
  padding: var(--link-padding-vertical) 0 var(--link-padding-vertical) calc(var(--link-icon-size) + var(--spacing-base));
  font-family: var(--font-family-futura-medium);
  color: var(--orange);
  text-decoration: none;
  transition: var(--transition-padding);

  &::before {
    @include icon(var(--link-icon-size), $color: var(--orange), $image: url("../images/icons/icon-arrow-right-20.svg"));
    left: 0;
    top: 50%;
    margin-top: calc((var(--link-icon-size) / 2) * -1);
    transition: var(--transition-left);
  }

  &:hover {
    &::before {
      left: 0.4rem;
    }
  }

  &:active {
    &::before {
      left: var(--link-padding-horizontal);
    }
  }

  &:focus-visible {
    @include focus-frame;
    padding-right: var(--link-padding-horizontal);
    padding-left: calc(var(--link-padding-horizontal) + var(--link-icon-size) + var(--spacing-base));
    border-radius: calc(var(--border-radius-small) - (var(--border-small) * 2));
    outline-offset: 0;

    &::before {
      left: var(--link-padding-horizontal);
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    @include link-color(var(--gray));
    cursor: default;

    &::before {
      left: 0;
    }
  }

  &--white {
    @include link-color(var(--white));
  }

  &--black {
    @include link-color(var(--black));
  }

  &--download {
    padding-left: calc($icon-size-small + var(--spacing-base));

    &::before {
      @include icon-image(url("../images/icons/icon-arrow-down.svg"));
      @include icon-size($icon-size-small);
      margin-top: calc(($icon-size-small / 2) * -1);
      transition: var(--transition-base);
    }

    &:hover::before {
      left: 0;
      margin-top: calc(($icon-size-small / 2) * -1 + 5px);
    }

    &:focus-visible {
      padding-left: calc(var(--link-padding-horizontal) + $icon-size-small + var(--spacing-base));
    }
  }

  &--clear {
    color: var(--white);
    padding-left: 0;
    padding-right: calc($icon-size-small + var(--spacing-base));
    border-bottom: var(--border-small) solid var(--white);
    padding-bottom: 0;

    &::before {
      content: none;
    }
    
    &::after {
      @include icon($icon-size-small, $color: var(--white), $image: url("../images/icons/icon-close.svg"));
      margin-top: calc(($icon-size-small / 2) * -1);
      top: calc(50% + 3px);
      right: 0;
    }

    &:hover {
      border-color: transparent;
    }

    &:focus-visible {
      @include focus-frame(var(--white));
      padding-left: 0;
      padding-right: calc($icon-size-small + var(--spacing-base));
    }
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .link {
    @include link-color(var(--black));

    &:disabled,
    &:disabled:hover {
      @include link-color(var(--gray));
    }
  }
}

.section-block--bg-dark-orange {
  .link {
    @include link-color(var(--clear-white));
  }
}

.section-block--bg-dark-purple {
  .link {
    @include link-color(var(--white));
  }
}
