﻿.icon {
  @include icon-plate-size($icon-size-medium, $icon-size-medium);
  display: block;
  position: relative;
  
  &::before {
    @include icon($icon-size-medium);
  }

  &--accessibility::before {
    @include icon-image(url("../images/icons/icon-accessibility.svg"));
  }

  &--arrow-down::before {
    @include icon-image(url("../images/icons/icon-arrow-down.svg"));
  }
  
  &--arrow-right::before {
    @include icon-image(url("../images/icons/icon-arrow-right-20.svg"));
  }
  
  &--arrow-head-down::before {
    @include icon-image(url("../images/icons/icon-arrow-head-down.svg"));
  }

  &--arrow-head-up::before {
    @include icon-image(url("../images/icons/icon-arrow-head-up.svg"));
  }
  
  &--arrow-head-left::before {
    @include icon-image(url("../images/icons/icon-arrow-head-left.svg"));
  }

  &--arrow-head-right::before {
    @include icon-image(url("../images/icons/icon-arrow-head-right.svg"));
  }

  &--arrow-head-alternative-left::before {
    @include icon-image(url("../images/icons/icon-arrow-head-alternative-left.svg"));
  }

  &--arrow-head-alternative-right::before {
    @include icon-image(url("../images/icons/icon-arrow-head-alternative-right.svg"));
  }
  
  &--baby-changing-station::before {
    @include icon-image(url("../images/icons/icon-baby-changing-station.svg"));
  }
  
  &--basket::before {
    @include icon-image(url("../images/icons/icon-basket.svg"));
  }

  &--binoculars::before {
    @include icon-image(url("../images/icons/icon-binoculars.svg"));
  }
  
  &--bolt::before {
    @include icon-image(url("../images/icons/icon-bolt.svg"));
  }
  
  &--book-opened::before {
    @include icon-image(url("../images/icons/icon-book-opened.svg"));
  }

  &--books::before {
    @include icon-image(url("../images/icons/icon-books.svg"));
  }

  &--box::before {
    @include icon-image(url("../images/icons/icon-box.svg"));
  }
  
  &--briefcase::before {
    @include icon-image(url("../images/icons/icon-briefcase.svg"));
  }
  
  &--calendar::before {
    @include icon-image(url("../images/icons/icon-calendar.svg"));
  }
  
  &--camera::before {
    @include icon-image(url("../images/icons/icon-camera.svg"));
  }

  &--car::before {
    @include icon-image(url("../images/icons/icon-car.svg"));
  }

  &--checkmark::before {
    @include icon-image(url("../images/icons/icon-checkmark.svg"));
  }

  &--city::before {
    @include icon-image(url("../images/icons/icon-city.svg"));
  }
  
  &--clock::before {
    @include icon-image(url("../images/icons/icon-clock.svg"));
  }

  &--close::before {
    @include icon-image(url("../images/icons/icon-close.svg"));
  }

  &--cup::before {
    @include icon-image(url("../images/icons/icon-cup.svg"));
  }
  
  &--display::before {
    @include icon-image(url("../images/icons/icon-display.svg"));
  }
  
  &--dumbbell::before {
    @include icon-image(url("../images/icons/icon-dumbbell.svg"));
  }
  
  &--earth::before {
    @include icon-image(url("../images/icons/icon-earth.svg"));
  }

  &--easy-to-read::before {
    @include icon-image(url("../images/icons/icon-easy-to-read.svg"));
  }
  
  &--facebook::before {
    @include icon-image(url("../images/icons/icon-facebook.svg"));
  }

  &--family::before {
    @include icon-image(url("../images/icons/icon-family.svg"));
  }
  
  &--file::before {
    @include icon-image(url("../images/icons/icon-file.svg"));
  }

  &--film-roll::before {
    @include icon-image(url("../images/icons/icon-film-roll.svg"));
  }
  
  &--flag::before {
    @include icon-image(url("../images/icons/icon-flag.svg"));
  }

  &--full-screen::before {
    @include icon-image(url("../images/icons/icon-full-screen.svg"));
  }

  &--guided-tour::before {
    @include icon-image(url("../images/icons/icon-guided-tour.svg"));
  }
  
  &--headphones::before {
    @include icon-image(url("../images/icons/icon-headphones.svg"));
  }
  
  &--heart::before {
    @include icon-image(url("../images/icons/icon-heart.svg"));
  }
  
  &--instagram::before {
    @include icon-image(url("../images/icons/icon-instagram.svg"));
  }

  &--kickbike::before {
    @include icon-image(url("../images/icons/icon-kickbike.svg"));
  }

  &--location::before {
    @include icon-image(url("../images/icons/icon-location.svg"));
  }
  
  &--map::before {
    @include icon-image(url("../images/icons/icon-map.svg"));
  }

  &--mic::before {
    @include icon-image(url("../images/icons/icon-mic.svg"));
  }
  
  &--minus::before {
    @include icon-image(url("../images/icons/icon-minus.svg"));
  }

  &--mobile::before {
    @include icon-image(url("../images/icons/icon-mobile.svg"));
  }

  &--music::before {
    @include icon-image(url("../images/icons/icon-music.svg"));
  }
  
  &--no-animals::before {
    @include icon-image(url("../images/icons/icon-no-animals.svg"));
  }
  
  &--no-kickbikes::before {
    @include icon-image(url("../images/icons/icon-no-kickbikes.svg"));
  }
  
  &--parking::before {
    @include icon-image(url("../images/icons/icon-parking.svg"));
  }
  
  &--people::before {
    @include icon-image(url("../images/icons/icon-people.svg"));
  }

  &--person::before {
    @include icon-image(url("../images/icons/icon-person.svg"));
  }
  
  &--play::before {
    @include icon-image(url("../images/icons/icon-play.svg"));
  }
  
  &--plus::before {
    @include icon-image(url("../images/icons/icon-plus.svg"));
  }
  
  &--price-tag::before {
    @include icon-image(url("../images/icons/icon-price-tag.svg"));
  }

  &--quotation-mark::before {
    @include icon-image(url("../images/icons/icon-quotation-mark.svg"));
  }

  &--railcar::before {
    @include icon-image(url("../images/icons/icon-railcar.svg"));
  }
  
  &--railway::before {
    @include icon-image(url("../images/icons/icon-railway.svg"));
  }
  
  &--ruler::before {
    @include icon-image(url("../images/icons/icon-ruler.svg"));
  }
  
  &--search::before {
    @include icon-image(url("../images/icons/icon-search.svg"));
  }
  
  &--sign-language::before {
    @include icon-image(url("../images/icons/icon-sign-language.svg"));
  }

  &--speech-bubbles::before {
    @include icon-image(url("../images/icons/icon-speech-bubbles.svg"));
  }

  &--speed::before {
    @include icon-image(url("../images/icons/icon-speed.svg"));
  }
  
  &--spoon-knife::before {
    @include icon-image(url("../images/icons/icon-spoon-knife.svg"));
  }
  
  &--star::before {
    @include icon-image(url("../images/icons/icon-star.svg"));
  }
  
  &--stroller::before {
    @include icon-image(url("../images/icons/icon-stroller.svg"));
  }
  
  &--thermometer::before {
    @include icon-image(url("../images/icons/icon-thermometer.svg"));
  }
  
  &--ticket::before {
    @include icon-image(url("../images/icons/icon-ticket.svg"));
  }

  &--timeline::before {
    @include icon-image(url("../images/icons/icon-timeline.svg"));
  }
  
  &--toilet::before {
    @include icon-image(url("../images/icons/icon-toilet.svg"));
  }
  
  &--train::before {
    @include icon-image(url("../images/icons/icon-train.svg"));
  }
  
  &--volume::before {
    @include icon-image(url("../images/icons/icon-volume.svg"));
  }

  &--walking::before {
    @include icon-image(url("../images/icons/icon-walking.svg"));
  }
  
  &--weight::before {
    @include icon-image(url("../images/icons/icon-weight.svg"));
  }
  
  &--wheelchair::before {
    @include icon-image(url("../images/icons/icon-wheelchair.svg"));
  }
  
  &--wrench::before {
    @include icon-image(url("../images/icons/icon-wrench.svg"));
  }
  
  &--youtube::before {
    @include icon-image(url("../images/icons/icon-youtube.svg"));
  }
}