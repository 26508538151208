// Icon sizes
$icon-size-xs: 1.2rem;     // 12px
$icon-size-small: 1.6rem;  // 16px
$icon-size-medium: 2.4rem; // 24px
$icon-size-large: 3.2rem;  // 32px
$icon-size-xl: 4.8rem;     // 48px

// Use to set the size of an icon as a pseudo element, e.g. ::before or ::after.
@mixin icon-size($size) {
    -webkit-mask-size: $size $size;
    mask-size: $size $size;
    width: $size;
    height: $size;
}

// Use to set the image of an icon as a pseudo element, e.g. ::before or ::after.
@mixin icon-color($color) {
  background-color: $color;
}

// Use to set the image of an icon as a pseudo element, e.g. ::before or ::after.
@mixin icon-image($image) {
  -webkit-mask-image: $image;
  mask-image: $image;
}

// Use to set an icon as a pseudo element, e.g. ::before or ::after.
@mixin icon($size, $color: var(--white), $image: null) {
  @include pseudo-block;
  @include icon-size($size);
  @include icon-color($color);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  @if $image != null {
    @include icon-image($image);
  }
}

// Use to set the size of the background plate of an icon.
@mixin icon-plate-size($width, $height) {
  width: $width;
  height: $height;
}

// Use to set the background plate of an icon.
@mixin icon-plate($width, $height, $background-color: transparent, $is-circle: false) {
  @include icon-plate-size($width, $height);
  background-color: $background-color;

  @if $is-circle {
    border-radius: var(--border-radius-round);
  }
}
