﻿@mixin image-border-radius {
  border-radius: var(--border-radius-large);

  @media(min-width: $md) {
    border-radius: var(--border-radius-xl);
  }
}

@mixin image-container-cover {
  @include flex-center-child;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}