.video-block {
  &__link-container {
    @include flex-column;
    align-items: flex-start;
    margin-top: var(--spacing-base);
  }
  
  &__image-video-container {
    position: relative;

    &:hover {
      cursor: pointer;

      .video-block__icon {
        border-color: transparent;

        .icon--play {
          @include center-absolute(calc($icon-size-medium + 5px), calc($icon-size-medium + 5px));

          &::before {
            @include icon-size(calc($icon-size-medium + 5px));
          }
        }
      }
    }
  }

  &__image-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;

    figure {
      width: 100%;
      object-fit: cover;
      transition: var(--transition-base);

      img {
        @include image-border-radius;
        aspect-ratio: 16/9;
        object-fit: cover;
      }
    }
  }
  
  &__image-overlay {
    @include image-border-radius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 16/9;
    transition: var(--transition-base);
    background-color: var(--black);
    opacity: 0.5;
    z-index: 1;
  }

  &__video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .video-player-container {
      width: 100%;
      height: 100%;
      padding: 0 !important;

      .video-player-container__text {
        div {
          display: none;
        }
      }

      .MP4-video{
        @include image-border-radius;
      }
    }
  }
  
  &__content {
    width: 100%;
    max-width: var(--max-width-box-wide);
    height: auto !important;

    &--align-top {
      align-self: flex-start;
    }

    &--prevent-video {
      height: 100%;

      .video-block__image-wrapper {
        z-index: 1;
      }

      .video-block__video {
        transition: background-color 150ms ease-in-out;
      }

      // After click on play icon
      &.video-block__content--show-video {
        &.video-block__content:hover {
          cursor: default;
        }

        .video-block__image-wrapper {
          display: none;
        }

        .video-block__video {
          height: 100% !important;
          position: static;
          background-color: var(--black);
          color: var(--white);
          z-index: 1;

          .video-player-container {
            display: flex;
            justify-content: center;

            .video-player-container__text {
              width: 70%;
              padding-top: var(--spacing-small);
              display: flex;
              justify-content: center;
              align-items: center;

              div {
                display: block;
              }
            }
          }
        }

        @media (min-width: $md) {
          .video-block__image-wrapper {
            display: block;
          }

          .video-block__video {
            position: absolute;
          }
        }
      }
    }

    &--load-video {
      .video-player-container {
        opacity: 0;
      }

      .video-block__image-overlay {
        opacity: 0.8;
      }

      .video-block__icon {
        background-color: transparent;
        border-color: transparent;

        .icon--play {
          opacity: 0;
        }

        .spinner {
          display: block;
        }
      }
    }

    &--show-video {
      .video-block__icon {
        display: none;
      }

      .video-block__image-wrapper{
        opacity: 0;
      }
      
      .video-block__image-overlay {
        display: none;
      }

      &.video-block__content--streamio {
        .streamio-player {
          display: block;
        }
      }

      .MP4-video {
        display: block;
      }
    }
    
    &--streamio {
      .streamio-player {
        width: 100%;
        height: 100%;
        display: none;
      }
    }
    
    video{
      display: none;
    }
  }

  &__icon {
    position: absolute;
    z-index: 1;
    opacity: 0;
    @include icon-plate(4.8rem, 4.8rem, var(--dark-orange), true);
    border: 2px solid var(--white);
    transition: var(--transition-base);

    &:focus-visible,
    &:active {
      border-color: transparent;

      .icon--play {
        @include center-absolute(calc($icon-size-medium + 5px), calc($icon-size-medium + 5px));

        &::before {
          @include icon-size(calc($icon-size-medium + 5px));
        }
      }
    }

    .icon--play {
      position: absolute;
      transition: var(--transition-base);
      @include center-absolute($icon-size-medium, $icon-size-medium);

      &::before {
        transition: var(--transition-base);
        @include icon-size($icon-size-medium);
      }
    }

    .spinner {
      display: none;
      margin-top: -4rem;
      width: 8rem;
      position: absolute;
      @include center-absolute(8rem, 8rem);
      top: 0;

      svg {
        height: auto;
        width: 100%;
      }
    }
  }

  @media (min-width: $md) {
    .content-area-item--twothirds {
      // Prevent video and after click on play-icon
      .video-block__content--prevent-video.video-block__content--show-video {
        .video-block__image-wrapper {
          display: none;
        }

        .video-block__video {
          position: static;
          min-height: 420px !important;
        }
      }
    }
  }

  @media (min-width: $lg) {
    .content-area-item--twothirds {
      // Prevent video and after click on play-icon
      .video-block__content--prevent-video.video-block__content--show-video {
        .video-block__image-wrapper {
          display: block;
        }

        .video-block__video {
          position: absolute;
          min-height: auto !important;
        }
      }
    }
  }
}