﻿@mixin border-radius-half-height($element-height) {
  border-radius: calc($element-height / 2);
}

@mixin full-screen-modal {
  overscroll-behavior: contain;
  pointer-events: auto;
  touch-action: auto;
}

@mixin sr-only {
  &:not(:focus):not(:active) {
    clip: rect(0,0,0,0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
