/* Variables */

:root {
 /*!
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Futura PT Book by ParaType
 * URL: https://www.myfonts.com/products/futura-book-futura-book-71737
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Futura PT Medium by ParaType
 * URL: https://www.myfonts.com/products/futura-medium-futura-book-71782
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */

  @font-face {
    font-family: "Futura PT Book";
    src: url('../fonts/futura-pt-book.woff') format('woff'), url('../fonts/futura-pt-book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Futura PT Medium";
    src: url('../fonts/futura-pt-medium.woff') format('woff'), url('../fonts/futura-pt-medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  // Font family
  --font-family-futura-book: "Futura PT Book", sans-serif;
  --font-family-futura-medium: "Futura PT Medium", sans-serif;

  // Font sizes
  --font-size-xxs: 1.2rem;          // 12px
  --font-size-xs: 1.4rem;           // 14px
  --font-size-small: 1.8rem;        // 18px
  --font-size-medium: 2.1rem;       // 21px
  --font-size-large: 2.4rem;        // 24px
  
  --font-size-heading-xxl: clamp(4.8rem, 5vw, 8rem);
  --font-size-heading-xl: clamp(4.0rem, 5vw, 5.4rem);
  --font-size-heading-large: clamp(2.8rem, 3vw, 3.2rem);
  --font-size-heading-medium: clamp(2.6rem, 2vw, 2.8rem);
  --font-size-heading-small: var(--font-size-large);
  --font-size-heading-xs: var(--font-size-medium);

  // Line heights
  --line-height-small: 1.2;
  --line-height-body: 1.381;
  --line-height-medium: 1.5;

  --line-height-heading-xxl: 1;
  --line-height-heading-xl: 1.075;
  --line-height-heading-large: 1.125;
  --line-height-heading-medium: var(--line-height-small);
  --line-height-heading-small: var(--line-height-small);
  --line-height-heading-xs: var(--line-height-small);
  
  // Spacing
  --margin-body-text: 1.334em;
  --margin-small-text-5: 0.278em;
  --margin-small-text-10: 0.556em;
  --margin-list-item: 0.477em;
  --margin-list-item-bottom: 1em;
  
  --margin-heading-xl-32: 0.6em;
  --margin-heading-xl-40: 0.741em;
  --margin-heading-xl-80: 1.482em;
  --margin-heading-large-20: 0.625em;
  --margin-heading-large-40: 1.25em;
  --margin-heading-medium-5: 0.179em;
  --margin-heading-medium-10: 0.358em;
  --margin-heading-medium-15: 0.536em;
  --margin-heading-medium-30: 1.072em;
  --margin-heading-medium-40: 1.429em;
  --margin-heading-small-5: 0.209em;
  --margin-heading-xs-10: 0.477em;
  --margin-heading-xs-20: 0.953em;
  --margin-heading-xs-40: 1.905em;
}

/* General style rules */
body {
  font-family: var(--font-family-futura-book);
  font-size: var(--font-size-medium);
  line-height: var(--line-height-body);
  color: var(--white);
}

button,
input,
textarea,
select {
  color: var(--black);
  font: inherit;
}

strong {
  font-family: var(--font-family-futura-medium);
  font-weight: normal;
}
