.symbol-block {
  &__content {
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    gap: var(--spacing-small);
    width: 100%;
  }

  &__icon {
    @include icon-plate-size($icon-size-xl, $icon-size-xl);
    
    &::before {
      @include icon-size($icon-size-xl);
    }
  }

  &__information {
    flex: 1;
  }
  
  &__text {
    *:last-child {
      margin-bottom: 0;
    }
  }

  &.content-area-item--full &__content {
    width: auto;
    max-width: var(--max-full-width-text);
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .symbol-block__icon::before {
    @include icon-color(var(--black));
  }
  
  .symbol-block__content {
    color: var(--black);
  }
}

.section-block--bg-dark-orange {
  .symbol-block__icon::before {
    @include icon-color(var(--clear-white));
  }

  .symbol-block__content {
    color: var(--clear-white);
  }
}
