.footer {
  $self: &;
  
  padding-top: var(--spacing-large);
  padding-bottom: var(--spacing-large);
  position: relative;
  background-color: var(--black);
  z-index: 2;
  
  a:focus-visible {
    outline-color: var(--white);
  }
  
  &__content {
    @include container;
    position: relative;

    &::before {
      @include pseudo-block;
      width: calc(100% - (var(--spacing-xs) * 2));
      height: var(--border-xs);
      top: calc((var(--spacing-large) + var(--border-xs)) * -1);
      left: var(--spacing-xs);
      background-color: var(--green-blue-20);

      @media (min-width: $sm) {
        width: calc(100% - (var(--spacing-small) * 2));
        left: var(--spacing-small);
      }

      @media (min-width: $md) {
        width: calc(100% - (var(--spacing-medium) * 2));
        left: var(--spacing-medium);
      }

      @media (min-width: $lg) {
        width: calc(100% - (var(--spacing-large) * 2));
        left: var(--spacing-large);
      }

      @media (min-width: $xl) {
        width: calc(100% - (var(--spacing-xl) * 2));
        left: var(--spacing-xl);
      }
    }
  }

  &__information-container {
    @include flex-container-column;
    align-items: stretch;
    gap: var(--spacing-medium);
    padding: 0 calc(var(--spacing-medium) - var(--spacing-xs));
    
    @media (min-width: $md) {
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: var(--spacing-xl);
      padding: 0;
      margin-bottom: var(--spacing-large);
    }
    
    #{$self}__information {
      & > *:last-child {
        margin-bottom: 0;
      }
      
      ul,
      ol {
        list-style: none;
        padding-left: 0;
      }

      #{$self}__social-media-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__logotypes {
    @include flex-container-column;
    padding-top: var(--spacing-xl);

    @media (min-width: $md) {
      flex-direction: row;
      align-items: flex-start;
      gap: var(--spacing-medium);
      border-top: var(--border-xs) solid var(--green-blue-20);
      padding-top: var(--spacing-medium);
    }

    @media (min-width: $lg) {
      align-items: center;
      gap: var(--spacing-xl);
    }

    @media (min-width: $xl) {
      gap: var(--spacing-xxl);
    }
  }

  &__main-logo {
    margin-bottom: var(--spacing-medium);

    @media (min-width: $md) {
      width: 130px;
      margin-bottom: 0;
    }

    @media (min-width: $lg) {
      width: 190px;
    }
  }
  
  &__other-museums {
    @media (min-width: $md) {
      flex: 1;
    }
  }

  &__other-museums-text p {
    text-align: center;
    margin-bottom: var(--spacing-large);

    @media (min-width: $md) {
      text-align: left;
      margin-bottom: var(--spacing-small);
    }
  }

  &__other-museums-logo-list {
    @include horizontal-list;
    justify-content: space-between;
    row-gap: var(--spacing-medium);

    @media (min-width: $xl) {
      padding-right: var(--spacing-medium);
    }
  }

  &__other-museums-item {
    @include flex-center-child;
    padding: 0 var(--spacing-xs);
    width: 50%;

    @media (min-width: $sm) {
      padding: 0 var(--spacing-large);
    }

    @media (min-width: $md) {
      padding: 0;
      width: calc(25% - var(--spacing-xs));
    }

    @media (min-width: $lg) {
      width: calc(25% - calc(var(--spacing-base) * 3));
    }

    @media (min-width: $xl) {
      width: calc(25% - var(--spacing-large));
    }
  }
  
  &__other-museums-link {
    @include flex-center-child;
    min-height: var(--click-surface-min-size);
  }

  &__other-museums-logo {
    max-width: 14rem;
    max-height: 6.2rem;
  }
  
  a {
    color: var(--white);
  }
}

/* Optimizely preview mode */
body.is-in-preview-mode .footer {
  padding-bottom: 25rem;
}