@mixin horizontal-divider {
  width: 100%;
  height: 0;
  margin: var(--spacing-medium) 0;
  border-right: none;
  border-bottom: var(--border-small) solid;
}

@mixin vertical-divider {
  width: var(--border-small);
  height: auto;
  margin: 0 var(--spacing-medium);
  border-bottom: none;
  border-right: var(--border-small) solid;
}

.opening-hours-and-prices-block {
  $self: &;
  
  z-index: 2;

  @include container;
  max-width: calc(var(--max-width-content-wide) + var(--spacing-xl) * 2);
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-large);

  @media(min-width: $md) {
    margin-top: var(--spacing-xl);
  }

  @media(min-width: $xl) {
    margin-top: var(--spacing-xxl);
  }

  &--light-bg {
    margin-top: var(--spacing-medium);

    @media(min-width: $md) {
      margin-top: var(--spacing-large);
    }

    @media(min-width: $xl) {
      margin-top: var(--spacing-xl);
    }

    #{$self}__content {
      padding: 0;
      border-radius: var(--border-radius-large);
      color: var(--black);
      
      &--show-image {
        max-width: var(--max-width-content-wide);

        #{$self}__text-container {
          @media(min-width: $lg) {
            padding: var(--spacing-xl) var(--spacing-large);
          }
        }
      }
    }

    #{$self}__image {
      border-radius: 0;
    }

    #{$self}__text-container {
      background-color: var(--green-blue-20);
      padding: var(--spacing-medium) var(--spacing-small);

      @media(min-width: $sm) {
        padding: var(--spacing-large);
      }

      @media(min-width: $lg) {
        padding: var(--spacing-xl);
      }
    }

    #{$self}__divider {
      border-color: var(--black);

      @media(min-width: $lg) {
        margin: 0 var(--spacing-large);
      }
    }

    #{$self}__info-list {
      font-size: var(--font-size-medium);
    }
    
    #{$self}__link {
      @include link-color(var(--black));
    }
    #{$self}__button {
      @include button-color(var(--green-blue), var(--dark-green-blue), var(--white));
    }

    .heading__icon::before {
      @include icon-color(var(--black));
    }
  }

  &__content {
    width: 100%;
    max-width: var(--max-width-box-wide);
    padding: 0 var(--spacing-xs);
    overflow: hidden;

    @media(min-width: $md) {
      padding: 0;
    }

    &--show-image {
      display: flex;
      flex-direction: column;
      max-width: var(--max-width-content-wide);

      @media(min-width: $lg) {
        flex-direction: row;
      }

      #{$self}__text-container {
        padding-top: var(--spacing-medium);

        @media(min-width: $sm) {
          padding-top: var(--spacing-large);
        }
        
        @media(min-width: $lg) {
          @include flex-container-column;
          order: 1;
          padding-top: 0;
          padding-right: var(--spacing-large);
          flex-basis: 37.891%;
        }
      }

      #{$self}__divider {
        @media(min-width: $lg) {
          @include horizontal-divider;
        }
      }
    }
  }

  &__image {
    @include image-border-radius;
    max-height: 25rem;
    overflow: hidden;
    position: relative;

    @media(min-width: $lg) {
      order: 2;
      max-height: none;
      flex-basis: 62.109%;
    }

    img {
      display: inline-flex;
      justify-self: center;
      align-self: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  &__caption {
    display: none;

    @media(min-width: $sm) {
      display: block;
    }
  }

  &__text-container {
    @include flex-container-column;

    @media(min-width: $md) {
      flex-direction: row;
      align-items: stretch;
    }
  }

  &__column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__divider {
    @include horizontal-divider;
    border-color: var(--white);
    display: block;

    @media(min-width: $md) {
      @include vertical-divider;
    }

    @media(min-width: $lg) {
      margin: 0 var(--spacing-xl);
    }
  }

  &__info-list {
    font-size: var(--font-size-large);
  }

  &__list-term {
    font-family: var(--font-family-futura-book);

    &--main,
    &--extra {
      display: block;
      width: 100%;
    }

    &--extra {
      font-size: var(--font-size-small);
    }
  }

  &__cta-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: auto;
  }
  
  &__link {
    @include link-color(var(--white));
    margin-top: var(--spacing-small);
  }
  
  &__button {
    align-self: center;
    margin-top: var(--spacing-medium);

    @media(min-width: $md) {
      align-self: flex-start;
    }

    @media(min-width: $lg) {
      margin-top: var(--spacing-small);
    }
  }
  
  &__link + &__button {
    @media(min-width: $lg) {
      margin-top: var(--spacing-small);
    }
  }
}
