﻿.cookie-declaration-block {
  overflow: hidden;
  
  & > div {
    width: 100%;
    
    p {
      margin-bottom: var(--margin-body-text);
    }
  }
  
  .CookieDeclarationType {
    border-color: var(--black-75);
    border-radius: var(--border-radius-small);
    padding: var(--spacing-base);
    margin: var(--spacing-small) 0;
  }

  .CookieDeclarationTypeHeader,
  .CookieDeclarationTypeDescription {
    padding: 0 var(--spacing-base);
  }

  .CookieDeclarationTypeHeader {
    font-family: var(--font-family-futura-medium);
    font-size: var(--font-size-heading-medium);
    font-weight: normal;
    line-height: var(--line-height-heading-medium);
    margin: var(--margin-heading-medium-15) 0;
  }

  .CookieDeclarationTable {
    border: var(--border-small) solid var(--white);
    font-size: var(--font-size-xs);
    margin: 0;
    width: 100%;

    .CookieDeclarationTableHeader,
    .CookieDeclarationTableCell {
      border: var(--border-xs) solid var(--white);
      padding: calc(var(--spacing-base)/2);
      vertical-align: top;
    }

    .CookieDeclarationTableHeader {
      font-family: var(--font-family-futura-medium);
      font-weight: normal;
      line-height: var(--line-height-small);
    }

    @media(min-width: $lg) {
      font-size: var(--font-size-small);

      .CookieDeclarationTableHeader,
      .CookieDeclarationTableCell {
        padding: var(--spacing-base);
      }
    }
  }
}

.section-block--bg-white,
.section-block--bg-light-green-blue {
  .cookie-declaration-block {
    color: var(--black);
    
    .CookieDeclarationTable,
    .CookieDeclarationTableHeader,
    .CookieDeclarationTableCell {
      border-color: var(--black);
    }
  }
}
