﻿.menu {
  $self: &;
  
  @include clear-list;
  @include main-navigation-block;
  @include flex-container-column;
  padding-right: 0;
  align-items: stretch;
  gap: var(--spacing-base);

  @media(min-width: $sm) {
    padding-right: 3rem;
  }
  
  &__item-container {
    @include menu-item-container;
  }
  
  &__item--selected {
    #{$self}__link::after {
      width: 100%;
    }
  }
  
  &__link {
    @include menu-link;
    padding: 0.7rem 0 ;
    font-size: var(--font-size-large);
    line-height: 1.25;
  }
  
  &__submenu-toggle-button {
    width: var(--click-surface-min-size);
    height: var(--click-surface-min-size);
    transition: var(--transition-base);
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: var(--border-radius-small);
    }
    
    &:focus-visible {
      @include focus-frame(var(--white));
    }

    &--expanded {
      #{$self}__submenu-toggle-icon::before {
        @include icon-image(url("../images/icons/icon-arrow-head-up.svg"));
      }
    }
  }
  
  &__submenu-toggle-icon {
    @include icon-plate-size(var(--click-surface-min-size), var(--click-surface-min-size));
    
    &::before {
      @include icon-size($icon-size-small);
      @include icon-image(url("../images/icons/icon-arrow-head-down.svg"));
      @include center-absolute($icon-size-small, $icon-size-small);
      transition: var(--transition-base);           
    }
  }
}
