﻿.input-range {
  @include form-group;

  &__slider {
    width: 100%;
    height: var(--border-small);
    background-color: var(--white);

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: var(--white);
      border: none;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: var(--white);
      border: none;
    }

    &:focus::-moz-range-thumb,
    &:focus::-webkit-slider-thumb {
      background: var(--white);
    }

    &:focus::-webkit-slider-thumb {
      background: var(--white);
    }
  }

  &__min, &__max {
    line-height: var(--line-height-small) !important;
    margin-top: var(--spacing-base);
  }
}
