@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-container-column {
    @include flex-column;
    align-items: center;
}

@mixin flex-container-row {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
}

@mixin flex-center-child {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin container {
    width: 100%;
    max-width: calc(var(--max-width-content) + (var(--spacing-xl) * 2));
    margin-right: auto;
    margin-left: auto;
    padding-left: var(--spacing-xs);
    padding-right: var(--spacing-xs);

    @media(min-width: $sm) {
        padding-left: var(--spacing-small);
        padding-right: var(--spacing-small);
    }

    @media(min-width: $md) {
        padding-left: var(--spacing-medium);
        padding-right: var(--spacing-medium);
    }

    @media(min-width: $lg) {
        padding-left: var(--spacing-large);
        padding-right: var(--spacing-large);
    }

    @media(min-width: $xl) {
        padding-left: var(--spacing-xl);
        padding-right: var(--spacing-xl);
    }
}

@mixin content-area-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &--third {
        @media(min-width: $lg) {
            width: 31.897%;
        }
    }

    &--half {
        @media(min-width: $md) {
            width: 47.845%;
        }
    }

    &--twothirds {
        @media(min-width: $lg) {
            width: 63.794%;
        }
    }

    &--dropout {
        @include dropout-of-container;
    }
}

@mixin dropout-of-container {
    width: calc(100% + (var(--spacing-xs) * 2));
    margin: 0 calc(var(--spacing-xs) * -1);

    @media(min-width: $sm) {
        width: calc(100% + (var(--spacing-small) * 2));
        margin: 0 calc(var(--spacing-small) * -1);
    }

    @media(min-width: $md) {
        width: calc(100% + (var(--spacing-medium) * 2));
        margin: 0 calc(var(--spacing-medium) * -1);
    }

    @media(min-width: $lg) {
        width: calc(100% + (var(--spacing-large) * 2));
        margin: 0 calc(var(--spacing-large) * -1);
    }

    @media(min-width: $xl) {
        width: calc(100% + (var(--spacing-xl) * 2));
        margin: 0 calc(var(--spacing-xl) * -1);
    }
}

@mixin section-spacing {
    padding: var(--spacing-large) 0;

    @media(min-width: $md) {
        padding: var(--spacing-xl) 0;
    }

    @media(min-width: $xl) {
        padding: var(--spacing-xxl) 0;
    }
}

@mixin pseudo-block {
    content: "";
    display: block;
    position: absolute;
}

@mixin center-absolute($width, $height) {
    left: 50%;
    top: 50%;
    margin-left: calc(($width/2) * -1);
    margin-top: calc(($height/2) * -1);
}

@mixin fill-container-absolute {
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
