﻿.search-form {
  $self: &;
  
  @include main-navigation-block;
  margin-bottom: calc(var(--spacing-base) * 3);
  position: relative;
  transition: var(--transition-base);
  
  &__field {
    width: 100%;
    height: var(--click-surface-min-size);
    border-top: var(--border-small) solid var(--black);
    border-bottom: var(--border-small) solid var(--white);
    font-size: var(--font-size-small);
    color: var(--white);
    transition: var(--transition-border);

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { 
      display: none;
    }
    
    &:focus-visible {
      border: var(--border-small) solid var(--white);
      border-radius: var(--border-radius-small);
      padding-left: 0.6rem;

      & + #{$self}__button {
        right: 1.2rem;
        
        @media(min-width: $sm) {
          right: 4.2rem;
        }
      }
    }
    
    &::placeholder {
      color: var(--gray);
    }
  }
  
  &__button {
    --inner-button-size-border: calc(var(--click-surface-min-size) - (var(--border-small) * 2));
    
    width: var(--click-surface-min-size);
    height: var(--click-surface-min-size);
    position: absolute;
    top: 0;
    right: 0.4rem;
    border: var(--border-small) solid transparent;
    transition: var(--transition-base);

    &:focus-visible {
      border: var(--border-small) solid var(--white);
      border-radius: var(--border-radius-small);
      right: 1.4rem;

      @media(min-width: $sm) {
        right: 4.4rem;
      }
    }

    @media(min-width: $sm) {
      right: calc(4.4rem - var(--spacing-base));
    }
  }
  
  &__icon {
    @include icon-plate-size(var(--inner-button-size-border), var(--inner-button-size-border));
    
    &::before {
      @include center-absolute($icon-size-medium, $icon-size-medium);
    }
  }
}