﻿@mixin button-secondary {
  background-color: transparent;
  color: var(--orange);

  &:hover,
  &:active,
  &:focus-visible {
    color: var(--black);

    .button__icon::before {
      @include icon-color(var(--black));
    }
  }

  &:focus-visible {
    background-color: var(--orange);
  }

  .button__icon::before {
    @include icon-color(var(--orange));
  }
}

@mixin button-color($base-color, $hover-color, $text-color) {
  background-color: $base-color;
  border-color: $base-color;

  &,
  &:hover,
  &.button--secondary:hover,
  &:active,
  &.button--secondary:active,
  &:focus-visible,
  &.button--secondary:focus-visible {
    color: $text-color;
  }

  &:hover,
  &.button--secondary:hover,
  &:active,
  &.button--secondary:active,
  &:hover:focus-visible,
  &.button--secondary:hover:focus-visible {
    background-color: $hover-color;
    border-color: $hover-color;
  }
  
  &:active,
  &:focus-visible:active,
  &.button--secondary:active,
  &.button--secondary:focus-visible:active {
    border-top-color: rgba(0, 0, 0, 0.2);
    border-right-color: rgba(0, 0, 0, 0.2);
    border-left-color: rgba(0, 0, 0, 0.2);
  }

  &:focus-visible,
  &.button--secondary:focus-visible {
    outline-color: $base-color;
    background-color: $base-color;
  }

  .button__icon::before,
  &.button--secondary:disabled .button__icon::before,
  &.button--secondary:hover .button__icon::before,
  &.button--secondary:active .button__icon::before,
  &.button--secondary:focus-visible .button__icon::before {
    @include icon-color($text-color);
  }

  &.button--secondary {
    background-color: transparent;
    color: $base-color;

    &:disabled {
      background-color: var(--gray);
      color: var(--white);
    }

    .button__icon::before {
      @include icon-color($base-color);
    }
  }
}
