.block-card {
  $self: &;

  border-radius: var(--border-radius-large);
  position: relative;
  margin: 0;
  padding: var(--spacing-medium);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  &--has-image {
    @include block-card-image-portrait;

    #{$self}__image {
      border-radius: var(--border-radius-large);
      overflow: hidden;
    }

    #{$self}__content {
        flex: 1;
    }
  }
  
  &--stretch {
    align-self: stretch;

    #{$self}__content {
      height: 100%;
    }
    
    #{$self}__cta-container {
      margin-top: auto;
    }
  }
  
  &__content {
    display: inline-flex;
    flex-direction: column;
    height: auto;

    & > * {
      word-break: break-word;
      width: 100%;
    }
  }

  &__heading {
    margin-top: 0;
    margin-bottom: var(--margin-heading-medium-5);
  }
  
  &__body {
    margin-bottom: 1em;
  }
  
  &__label {
    font-size: var(--font-size-small);
    margin-bottom: var(--margin-small-text-5);
  }
  
  &__heading + &__cta-container {
    padding-top: var(--margin-body-text);
  }
  
  &__link {
    display: inline-block;
  }
}

@media(min-width: $md) {
  .content-area-item--full .block-card,
  .content-area-item--twothirds .block-card,
  .content-area-item--third .block-card {
    &--has-image {
      @include block-card-image-landscape;
    }
  }
}

@media(min-width: $lg) {
  .content-area-item--third .block-card {
    &--has-image {
      @include block-card-image-portrait;
    }
  }
}

@media(min-width: $xl) {
  .content-area-item--half .block-card {
    &--has-image {
      @include block-card-image-landscape;
    }
  }
}
