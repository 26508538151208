.input-text {
  $self: &;
  
  @include form-group;
  
  &__input,
  &__textarea {
    @include input-text;
    
    &--with-icon {
      @include input-text-icon;

      &:enabled:hover,
      &:focus-visible {
        padding-right: 4.2rem;
      }
    }
    
    &--on-light-bg {
      border-color: var(--black);

      &:enabled:hover {
        border-color: var(--black);
      }

      &:focus,
      &:focus:hover,
      &:focus-visible,
      &:focus-visible:hover {
        border-color: var(--black);
        color: var(--black);

        &::placeholder {
          color: var(--black-75);
        }
      }
    }
  }
  
  &__textarea {
    border-radius: var(--border-radius-medium);
    min-height: var(--text-area-height);
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    
    &.input-text__input--with-icon {
      background-position: right 1.4rem top 1.4rem;
    }
  }
  
  &--valid,
  &--valid:hover {
    #{$self}__input,
    #{$self}__textarea {
      border-color: var(--green-20);
      background-color: var(--green-20);
      background-image: url(../images/icons/icon-input-valid.svg);

      &:focus-visible {
        background-color: transparent;
      }
    }
  }
  
  &--invalid,
  &--invalid:hover {
    #{$self}__input,
    #{$self}__textarea {
      border-color: var(--red-25);
      background-color: var(--red-25);
      background-image: url(../images/icons/icon-input-invalid.svg);
      
      &:focus-visible {
        background-color: transparent;
      }
    }
  }

  &--valid,
  &--valid:hover,
  &--invalid,
  &--invalid:hover {
    #{$self}__input--on-light-bg,
    #{$self}__textarea--on-light-bg {
      border-color: var(--black);
    }
  }

  &--search {
    flex-direction: row;
    gap: var(--spacing-small);
    position: relative;
    margin-bottom: 0;

    .input-text__input {
      flex: 1;
    }
  }
}
