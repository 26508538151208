﻿.caption {
  $self: &;
  
  position: relative;
  font-size: var(--font-size-small);
  
  &--place-on-image {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-top: 0;
    max-width: calc(100% - var(--spacing-xs));
    
    #{$self}__byline {
      position: static;
      max-width: 100%;
    }
  }
  
  &__heading,
  &__heading:first-child {
    margin-top: var(--spacing-small);
    margin-bottom: calc(var(--spacing-base) / 2);
    
    & + #{$self}__description {
      margin-top: 0;
    }

    & + #{$self}__byline {
      bottom: calc(100% + var(--spacing-small));
    }
  }
  
  &__description {
    display: block;
    margin-top: var(--spacing-base);
    margin-bottom: 0;
    
    & + #{$self}__byline {
      bottom: calc(100% + var(--spacing-base));
    }
  }
  
  &__byline,
  &__byline-bottom {
    font-size: 1.6rem;
  }
  
  &__byline {
    display: block;
    width: auto;
    max-width: calc(100% - var(--spacing-small));
    position: absolute;
    right: 0;
    bottom: 100%;
    color: var(--white);
    background-color: rgba(0, 0, 0, 0.8);
    border-top-left-radius: var(--border-radius-small);
    padding: var(--spacing-base) var(--spacing-xs);
    text-align: left;

    @media(min-width: $sm) {
      padding-right: var(--spacing-small);
      padding-left: var(--spacing-small);
    }

    @media(min-width: $md) {
      max-width: calc(100% - var(--spacing-medium));
    }
  }
  
  &__byline-bottom {
    display: block;
    margin-top: 0.5em;
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .caption {
    color: var(--black);
  }
}