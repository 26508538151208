@mixin block-card-image-portrait {
  margin: 6.5rem 0 0;
  padding-top: var(--spacing-xl);

  .block-card__image {
    width: 13rem;
    height: 13rem;
    position: absolute;
    left: var(--spacing-medium);
    top: -6.5rem;
  }
}

@mixin block-card-image-landscape {
  margin: 0;
  padding-top: var(--spacing-medium);
  align-items: center;
  gap: var(--spacing-medium);
  
  .block-card__image {
    width: 14.6rem;
    height: 14.6rem;
    position: static;
  }
}

@mixin block-card-color($bg-color, $text-color, $link-color) {
  background-color: $bg-color;
  color: $text-color;
  
  .heading__icon::before {
    @include icon-color($text-color)
  }

  a {
    color: $link-color;
    
    &:focus-visible {
      outline-color: $link-color;
    }
  }

  .link {
    @include link-color($link-color);
  }
}

@mixin block-card-light-green-blue {
  @include block-card-color(var(--green-blue-20), var(--black), var(--dark-orange-darken));
  
  .info-list__details a {
    color: var(--black);
    
    &:focus-visible {
      outline-color: var(--black);
    }
  }
  
  .button--green-blue {
    color: var(--white);
    
    &:focus-visible {
      outline-color: var(--green-blue);
    }
  }
}

@mixin block-card-light-orange {
  @include block-card-color(var(--orange-20), var(--black), var(--dark-orange-darken));
}
