﻿.page-footer {
  @include container;
  padding-top: var(--spacing-small);
  padding-bottom: var(--spacing-small);
  position: relative;
  text-align: center;
  z-index: 2;

  @media(min-width: $md) {
    padding-top: var(--spacing-medium);
    padding-bottom: var(--spacing-medium);
  }
  
  *:last-child {
    margin-bottom: 0;
  }
  
  &__cookies-link {
    color: var(--white);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    
    &:focus-visible {
      outline-color: var(--white);
    }
  }
}
