﻿.shortcut-menu {
  @include horizontal-list;
  align-items: flex-start;
  gap: var(--spacing-medium);
  padding-top: 0.2rem;
  font-family: var(--font-family-futura-medium);
  font-size: var(--font-size-large);
  line-height: var(--line-height-small);

  @media(min-width: $xl) {
    gap: var(--spacing-large);
  }
  
  &__link {
    @include menu-link;
    gap: var(--spacing-base);
    
    &--highlighted {
      color: var(--orange);

      &:focus-visible {
        outline-color: var(--orange);
      }
      
      &::after {
        background-color: var(--orange);
      }
    }
  }
  
  &__icon {
    &::before {
      @include icon-color(var(--orange));
    }
  }
}