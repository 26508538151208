﻿.no-content {
  display: none;
  height: 24rem;
  width: 100%;
  padding: var(--spacing-medium);
  border: var(--border-small) dashed var(--gray);
  border-radius: var(--border-radius-large);

  @media(min-width: $md) {
    border-radius: var(--border-radius-xl);
  }

  p {
    margin-bottom: 0;
  }
}

.is-in-preview-mode .no-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .no-content {
    color: var(--black);
    border-color: var(--gray-50);
  }
}
