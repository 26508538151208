﻿.dropdown {
  $self: &;
  @include form-group;

  &__select {
    @include input-text;
    @include input-text-icon;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../images/icons/icon-arrow-head-down.svg");
    background-size: $icon-size-small $icon-size-small;
    cursor: pointer;
    border-color: transparent;
    
    &:focus,
    &:focus-visible {
      background-image: url("../images/icons/icon-arrow-head-down-white.svg");
    }
    
    option {
      background-color: var(--white);
      color: var(--black);

      &:checked {
        background-color: var(--gray-25);
      }
    }
  }

  &__placeholder {
    display: none;
  }
}
