.spinner {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 100%;
  
  svg {
    width: auto;
    height: 300px;

    path[id^="smoke"] {
      opacity: 0;
      transform-origin: center;
      animation: smokeAnimation 1s infinite alternate cubic-bezier(0.1, 0, 0.1, 1);
    }

    @for $i from 1 through 23 {
      $delay: 0.03s * $i;
      #smoke-#{$i} {
        animation-delay: $delay;
      }
    }
  }
}

@keyframes smokeAnimation {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(-6px);
  }
}
