.press-page {
  &__press-releases {
    @include clear-list;
  }
  
  &__filter {
    margin-bottom: var(--spacing-medium);
  }
  
  &__dropdown {
    margin-bottom: 0;
    width: 100%;
    max-width: 24.4rem;
  }
  
  &__press-release-published {
    text-transform: uppercase;
  }
}
