﻿@mixin form-group {
  @include flex-column;
  align-items: flex-start;
  margin-bottom: var(--spacing-small);
}

@mixin input-text {
  @include border-radius-half-height(var(--input-height));
  background-color: var(--white);
  border: var(--border-small) solid var(--white);
  height: var(--input-height);
  padding: 0 1.4rem;
  width: 100%;

  &::placeholder {
    color: var(--gray);
    opacity: 1;
  }

  &:enabled:hover {
    background-color: var(--orange-20);
    border-color: var(--orange-20);

    &::placeholder {
      color: var(--black-75);
    }
  }

  &:disabled {
    background-color: var(--black-25);
    border-color: var(--black-25);
  }
  
  &:focus,
  &:focus:hover,
  &:focus-visible,
  &:focus-visible:hover {
    background-color: transparent;
    border-color: var(--white);
    color: var(--white);
    
    &::placeholder {
      color: var(--gray);
    }
  }
}

@mixin input-text-icon {
  background-repeat: no-repeat;
  background-size: $icon-size-small $icon-size-small;
  background-position: right 1.4rem center;
  padding-right: 4.4rem;
}

@mixin check-element {
  position: relative;
  margin: var(--border-small);
  
  &__container {
    position: relative;
  }

  &__input {
    background: transparent;
    box-shadow: 0 0 0 var(--border-small) var(--white);
    cursor: pointer;
    display: block;
    height: 2rem;
    left: 0;
    position: absolute;
    top: calc(var(--spacing-base) + 0.45rem);
    width: 2rem;
    
    &,
    &::before,
    &::after {
      transition: var(--transition-base)
    }
    
    &:focus-visible {
      left: var(--spacing-base);
      
      & + .radio-button__label,
      & + .checkbox__label {
        @include focus-frame(var(--white));
        padding-right: var(--spacing-base);
        padding-left: calc(var(--spacing-base) + 2.4rem + 0.6rem);
      }
    }

    &:disabled {
      box-shadow: 0 0 0 var(--border-small) var(--gray);
      cursor: default;

      & + .radio-button__label,
      & + .checkbox__label {
        color: var(--gray);
        cursor: default;
      }
    }
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    padding: var(--spacing-base) 0 var(--spacing-base) calc(2.2rem + 0.6rem);
    transition: padding var(--transition-timing) ease-in-out;
  }
}
