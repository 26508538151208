h1,
h2,
h3,
h4,
.heading {
    font-family: var(--font-family-futura-medium);
    font-weight: normal;
    overflow-wrap: break-word;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.heading--xxl {
    font-size: var(--font-size-heading-xxl);
    line-height: var(--line-height-heading-xxl);
}

h1,
.heading--xl {
    font-size: var(--font-size-heading-xl);
    line-height: var(--line-height-heading-xl);
    margin-bottom: var(--margin-heading-xl-32);
}

h2,
.heading--large {
    font-size: var(--font-size-heading-large);
    line-height: var(--line-height-heading-large);
    margin-top: var(--margin-heading-large-40);
    margin-bottom: var(--margin-heading-large-20);
}

h3,
.heading--medium {
    font-size: var(--font-size-heading-medium);
    line-height: var(--line-height-heading-medium);
    margin-top: var(--margin-heading-medium-40);
    margin-bottom: var(--margin-heading-medium-15);
}

.heading--small {
    font-size: var(--font-size-heading-small);
    line-height: var(--line-height-heading-small);
}

h4,
.heading--xs {
    font-size: var(--font-size-heading-xs);
    line-height: var(--line-height-heading-xs);
    margin-top: var(--margin-heading-xs-40);
    margin-bottom: var(--margin-heading-xs-10);
}

.heading {
    $self: &;
    
    &--large#{$self}--standalone {
        margin-bottom: var(--margin-heading-large-40);
    }

    &--medium#{$self}--standalone {
        margin-bottom: var(--margin-heading-medium-30);
    }

    &--xs#{$self}--standalone {
        margin-bottom: var(--margin-heading-xs-20);
    }
    
    &--centered {
        text-align: center;
    }
    
    &--uppercase {
        text-transform: uppercase;
    }
    
    &--has-icon {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: var(--spacing-base);
    }

    &--small {
        #{$self}__icon {
            margin-top: 0.24rem;
        }
    }

    &--medium {
        #{$self}__icon {
            margin-top: 0.36rem;

            @media(min-width: $xl) {
                margin-top: 0.48rem;
            }
        }
    }
    
    &__text {
        flex: 1;
    }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
    .heading {
        color: var(--black);
        
        &__icon::before {
            @include icon-color(var(--black));
        }
    }
}

.section-block--bg-dark-orange {
    .heading {
        color: var(--clear-white);

        &__icon::before {
            @include icon-color(var(--clear-white));
        }
    }
}
