.image-block {
  $self: &;
  
  &__content {
    width: 100%;
    max-width: var(--max-width-box-wide);
    
    &--align-top {
      align-self: flex-start;
    }

    &--fixed-image-height {
      picture {
        @include image-container-cover;
        height: var(--fixed-image-height-xs);

        @media(min-width: $sm) {
          height: var(--fixed-image-height-sm);
        }
      }
    }
  }
  
  &.content-area-item--full {
    #{$self}__content--fixed-image-height {
      picture {
        @media(min-width: $md) {
          height: var(--fixed-image-height-md);
        }

        @media(min-width: $lg) {
          height: var(--fixed-image-height-lg);
        }
      }
    }
  }

  &.content-area-item--half {
    #{$self}__content--fixed-image-height {
      picture {
        @media(min-width: $md) {
          height: var(--fixed-image-height-xs);
        }

        @media(min-width: $lg) {
          height: var(--fixed-image-height-sm);
        }

        @media(min-width: $xl) {
          height: var(--fixed-image-height-md);
        }
      }
    }
  }

  &.content-area-item--third {
    #{$self}__content--fixed-image-height {
      picture {
        @media(min-width: $md) {
          height: var(--fixed-image-height-md);
        }

        @media(min-width: $lg) {
          height: var(--fixed-image-height-xs);
        }

        @media(min-width: $xl) {
          height: var(--fixed-image-height-sm);
        }
      }
    }
  }

  &.content-area-item--twothirds {
    #{$self}__content--fixed-image-height {
      picture {
        @media(min-width: $md) {
          height: var(--fixed-image-height-md);
        }
      }
    }
  }
  
  &__image-link {
    display: block;
    
    &:focus-visible {
      outline: none;
      
      #{$self}__image {
        outline: var(--border-small) solid var(--orange);
        outline-offset: var(--border-small);
      }
    }
  }
  
  &__image {
    @include image-border-radius;
    object-fit: cover;
  }
  
  &__byline {
    border-bottom-right-radius: var(--border-radius-large);
    padding-right: var(--spacing-small);
    display: none;

    @media(min-width: $sm) {
      display: block;
    }

    @media(min-width: $md) {
      border-bottom-right-radius: var(--border-radius-xl);
      padding-right: var(--spacing-medium);
    }
  }

  &.content-area-item--half {
    #{$self}__byline {
      @media(min-width: $md) {
        display: none;
      }

      @media(min-width: $xl) {
        display: block;
      }
    }
  }

  &.content-area-item--third {
    #{$self}__byline {
      @media(min-width: $lg) {
        display: none;
      }
    }
  }
  
  &__download-link {
    display: inline-block;
    margin-top: var(--spacing-base);
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .image-block {
    &__image-link:focus-visible {
      .image-block__image {
        outline-color: var(--black);
      }
    }
  }
}


