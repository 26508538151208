﻿.moment {
  position: absolute;
  cursor: pointer;

  &__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    top: 0;
    margin: auto;
    color: var(--white);

    h2 {
      margin-bottom: clamp(2rem, 2vw, 5rem);
    }
  }

  &__excerpt {
    position: absolute;
    top: clamp(16rem, 16vw, 32rem);
    margin: auto;
    width: 100%;
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:focus-visible {
      outline: 10px solid var(--white);
      outline-offset: 0;
    }
  }
}

.timeline-window--early-1900 ~ .moment__header {
  justify-content: space-between;
  row-gap: 5px;
  
  h2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50%;
    margin-bottom: 0;
  }
  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 50%;
  }
}

.timeline-window--early-2000 ~ .moment__excerpt {
  top: clamp(19rem, 19vw, 38rem);
}

.image-overlay {
  background-color: var(--black);
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.illustration {
  > .moment {
    left: clamp(15rem, 15vw, 26rem);
  }

  &--seat-1900 {
    > .moment {
      left: clamp(13rem, 13vw, 23rem);
    }
  }
}

.timeline-window--early-1900 + .moment__header + .moment__excerpt + .moment__button {
  border-radius: var(--border-radius-xs);
}

.timeline-window--late-1900 + .moment__header + .moment__excerpt + .moment__button {
  border-radius: 1.4rem;
}

.timeline-window--early-2000 + .moment__header + .moment__excerpt + .moment__button {
  border-radius: var(--border-radius-large);

  &:focus-visible {
    outline-color: var(--red-75);
  }
}
