﻿@mixin menu-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);
}

@mixin main-navigation-block {
  max-width: calc(33rem + (1.4rem * 2));
  padding-right: 1.4rem;
  padding-left: 1.4rem;
  margin-right: auto;
  margin-left: auto;

  @media(min-width: $sm) {
    max-width: calc(33rem + 6.4rem + 4.4rem);
    padding-right: 4.4rem;
    padding-left: 6.4rem;
  }
}

@mixin menu-link {
  display: flex;
  align-items: center;
  height: auto;
  min-height: var(--click-surface-min-size);
  position: relative;
  color: var(--white);
  text-decoration: none;

  &:focus-visible {
    outline-color: var(--white);

    &:hover::after {
      width: 0;
    }
  }

  &::after {
    @include pseudo-block;
    bottom: calc(var(--spacing-base) / 2);
    left: 0;
    height: var(--border-small);
    width: 0;
    background-color: var(--white);
    transition: var(--transition-width);
  }

  &:hover::after {
    width: 100%;
  }
}