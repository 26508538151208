.text-block {
  &__content {
    width: 100%;
    
    &--align-top {
      align-self: flex-start;
    }
  }
  
  &__body {
    *:last-child {
      margin-bottom: 0;
    }

    ol,
    ul {
      li:last-child {
        margin-bottom: var(--margin-list-item-bottom);
      }
    }
  }
  
  &.content-area-item--full &__content {
    max-width: var(--max-full-width-text);
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .text-block__content {
    color: var(--black);
  }
}

.section-block--bg-dark-orange {
  .text-block__content {
    color: var(--clear-white);
  }
}
