﻿.image-and-text-block {
  $self: &;
  
  padding: var(--spacing-base) 0;
  
  &:first-child {
    padding-top: 0;
  }
  
  &:last-child {
    padding-bottom: 0;
  }
  
  &__content {
    @include flex-container-row;
    width: 100%;

    @media(min-width: $sm) {
      justify-content: space-between;
    }
  }
  
  &__column {
    $column: &;
    
    @include content-area-item;

    @media(min-width: $md) {
      &--half#{$column}--right {
        order: 2;
      }
    }

    @media(min-width: $lg) {
      &--twothirds#{$column}--right,
      &--third#{$column}--right {
        order: 2;
      }
    }
  }
  
  &__image-block {
    margin-bottom: var(--spacing-medium);

    @media(min-width: $md) {
      &#{$self}__column--half {
        margin-bottom: 0;
      }
    }

    @media(min-width: $lg) {
      &#{$self}__column--twothirds,
      &#{$self}__column--third {
        margin-bottom: 0;
      }
    }
  }
}
