.divider {
  $self: &;
  
  position: relative;
  width: 100%;
  z-index: 2;
  
  &--rails {
    height: 6.5rem;
    margin-top: var(--spacing-large);
    background: url("../images/rails.svg") repeat-x center center;

    @media(min-width: $md) {
      margin-top: var(--spacing-xl);
    }

    @media(min-width: $xl) {
      margin-top: var(--spacing-xxl);
    }
  }
  
  &--rails#{$self}--behind-content {
      position: absolute;
      top: calc(50% - (6.5rem / 2));
      left: 0;
      margin-top: 0;
      z-index: -1;
  }
}

// On other background colors
.page-header--bg-dark-purple .divider--rails,
.section-block--bg-dark-purple .divider--rails {
  background-image: url("../images/rails-purple.svg");
}
