.button {
  $self: &;
  
  @include border-radius-half-height(var(--button-size));
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: var(--spacing-base) var(--spacing-small);
  background-color: var(--orange);
  border: var(--border-small) solid var(--orange);
  font-family: var(--font-family-futura-medium);
  color: var(--black);
  text-decoration: none;
  transition: background-color var(--transition-timing) ease-in-out, color var(--transition-timing) ease-in-out;

  &:hover,
  &:hover:focus-visible,
  &:active {
    background-color: var(--orange-50);
    border-color: var(--orange-50);
  }
  
  &:active,
  &:focus-visible:active {
    border-top-color: rgba(0, 0, 0, 0.2);
    border-right-color: rgba(0, 0, 0, 0.2);
    border-left-color: rgba(0, 0, 0, 0.2);
    box-shadow: var(--button-inner-shadow);
  }
  
  &:focus-visible {
    @include border-radius-half-height(var(--button-size));
    outline: var(--border-small) solid var(--orange);
    outline-offset: var(--border-small);
  }
  
  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    background-color: var(--gray);
    box-shadow: none;
    border-color: var(--gray);
    cursor: default;
    color: var(--white);
    opacity: 1;

    #{$self}__icon::before {
      @include icon-color(var(--white));
    }
  }
  
  &--secondary {
    @include button-secondary;
  }
  
  &--green-blue {
    @include button-color(var(--green-blue), var(--dark-green-blue), var(--white));
  }
  
  &--input-height {
    @include border-radius-half-height(var(--input-height));
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    min-height: var(--input-height);

    #{$self}__icon {
      @include icon-plate-size(2rem, 2rem);
      &::before {
        @include icon-size(2rem);
      }
    }
  }
  
  &--filter {
    padding: 0.45rem 1.8rem;
    background-color: transparent;
    border-color: var(--white);
    font-family: var(--font-family-futura-book);
    color: var(--white);
    
    &:hover,
    &:active,
    &:focus-visible,
    &:focus-visible:hover,
    &#{$self}--selected {
      background-color: var(--white);
      border-color: var(--white);
      color: var(--black);
    }
    
    &:active,
    &:focus-visible:active {
      border-top-color: rgba(0, 0, 0, 0.2);
      border-right-color: rgba(0, 0, 0, 0.2);
      border-left-color: rgba(0, 0, 0, 0.2);
    }

    &:focus-visible {
      outline-color: var(--white);
    }

    &#{$self}--selected {
      position: relative;
      padding-left: calc(1.8rem + $icon-size-medium + (var(--spacing-base) / 2));
      
      &::before {
        @include icon($icon-size-medium, var(--black), url("../images/icons/icon-checkmark.svg"));
        left: 1.8rem;
      }
    }
  }

  &__icon::before {
    @include icon-color(var(--black));
  }
  
  &__text {
    &--hidden-mobile {
      @media(max-width: $sm) {
        @include sr-only;
      }
    }
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .button {
    @include button-color(var(--green-blue), var(--dark-green-blue), var(--white));

    &:disabled,
    &:disabled:hover,
    &:disabled:active {
      background-color: var(--gray);
      box-shadow: none;
      border-color: var(--gray);

      .button__icon::before {
        @include icon-color(var(--white));
      }
    }
  }
}

.section-block--bg-dark-orange {
  .button {
    @include button-color(var(--clear-white), var(--orange-50), var(--black));
  }
}

.section-block--bg-dark-purple {
  .button {
    @include button-color(var(--white), var(--dark-orange-50), var(--black));
  }
}

.form-button-group {
  margin-top: var(--spacing-large);
}
