blockquote, .quote {
    font-family: var(--font-family-futura-medium);
    margin: var(--spacing-large) 0;
    padding-top: var(--spacing-medium);
    text-align: center;

    &::before {
        content: '”';
        display: block;
        font-size: 8rem;
        line-height: 0.8rem;
        text-align: center;
    }

    @media(min-width: $lg) {
        margin: var(--spacing-xl) 0;
    }
}
