﻿.header {
  @include full-screen-modal;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  transition: background-color var(--transition-timing) ease-in-out, top 0.4s ease-in-out;
  z-index: 5;
  
  &__skip-menu {
    @include clear-list;
  }
  
  &__skip-link {
    position: absolute;
    top: -100%;
    left: 0;
    display: flex;
    justify-content: center;
    width: calc(100% - var(--spacing-small));
    margin: var(--spacing-base);
    background-color: var(--orange);
    border-radius: var(--border-radius-small);
    text-decoration: none;
    transition: top 0.4s ease-in-out;
    z-index: 2;
    
    &:focus-visible {
      outline: none;
      top: 0;
    }
  }
  
  &__container {
    padding: var(--spacing-small);

    @media(min-width: $header-desktop-breakpoint) {
      padding: 0 var(--spacing-medium) var(--header-padding-bottom-desktop); 
    }

    @media(min-width: $xl) {
      padding-right: var(--spacing-large);
      padding-left: var(--spacing-large);
    }
  }
  
  &__inner-container {
    @include flex-container-row;
    align-items: flex-start;
    justify-content: flex-start;
    height: var(--header-toggle-button-height);

    @media(min-width: $md) {
      justify-content: center;
    }

    @media(min-width: $header-desktop-breakpoint) {
      justify-content: space-between;
      height: calc(var(--header-toggle-button-height) + var(--header-padding-middle-desktop) + var(--spacing-base));
      padding: var(--header-padding-middle-desktop) 21.9rem 0 0;
    }
  }
  
  &__logo {
    a {
      display: block;
      
      &:focus-visible {
        outline-color: var(--white);
      }
    }
    
    img {
      height: var(--header-logo-height-small);
      width: auto;

      @media(min-width: $md) {
        height: var(--header-logo-height-large);
      }
    }
  }
  
  &__mobile-content {
    display: block;

    @media(min-width: $header-desktop-breakpoint) {
      display: none;
    }
  }

  &__desktop-content {
    display: none;

    @media(min-width: $header-desktop-breakpoint) {
      display: block;
    }
  }
  
  &--hidden {
    top: calc(var(--header-height-mobile) * -1);

    @media(min-width: $header-desktop-breakpoint) {
      top: calc(var(--header-height-desktop) * -1);
    }
  }
}

.main-navigation-is-open .header {
  background-color: var(--black);
}
