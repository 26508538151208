.shortcuts-block {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__heading,
  &__text {
    text-align: center;
  }

  &__text {
    margin-bottom: var(--spacing-medium);
  }

  &__list {
    @include horizontal-list;
    gap: calc(var(--spacing-xs) * 2);
    max-width: var(--max-width-content-half);

    @media(min-width: $md) {
      column-gap: var(--spacing-xl);
      max-width: unset;
    }
  }

  &__item {
    @include flex-center-child;
    flex: 0 1 calc(50% - var(--spacing-xs));

    @media(min-width: $md) {
      flex-basis: auto;
    }
  }

  &__link {
    @include flex-container-column;
    justify-content: center;
    text-decoration: none;

    &:focus-visible {
      outline-color: var(--white);
    }

    &-icon {
      @include icon-plate-size($icon-size-large, $icon-size-large);
      margin-left: calc(var(--spacing-xs) * 2);

      &::before {
        @include icon-size($icon-size-large);
      }
    }
    
    &-text {
      @include link-color(var(--white));
    }
  }
}

// On other background colors
.section-block--bg-white,
.section-block--bg-light-green-blue {
  .shortcuts-block {
    &__heading,
    &__text {
      color: var(--black);
    }

    &__link {
      &:focus-visible {
        outline-color: var(--black);
      }

      &-icon::before {
        @include icon-color(var(--black));
      }

      &-text {
        @include link-color(var(--black));
      }
    }
  }
}
