﻿.sidebar {
  border-left: var(--border-large) solid var(--white);
  padding: 0 0 0 var(--spacing-medium);
  width: 100%;

  @media(min-width: $lg) {
    width: 30rem;
  }

  @media(min-width: $xl) {
    width: 36rem;
    padding: 0 0 0 var(--spacing-large);
  }
  
  &__item-term,
  &__item-details {
    width: 100%;
  }
  
  &__item-term {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-small);
  }
  
  &__item-icon {
    @include icon-plate-size($icon-size-small, calc($icon-size-small + 0.5rem));
    padding-top: 0.5rem;
    
    &::before {
      @include icon-size($icon-size-small);
    }
  }
  
  &__item-title {
    font-family: var(--font-family-base-futura-medium);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-body);
    margin: 0;
    flex: 1;
  }
  
  &__item-details {
    padding-left: calc($icon-size-small + var(--spacing-small));
    margin-bottom: var(--spacing-small);
    
    &:last-child,
    p {
      margin-bottom: 0;
    }
  }
  
  .call-to-action {
    padding-left: calc($icon-size-small + var(--spacing-small));
  }
}

// On other background colors
.section-block--bg-white .sidebar {
  border-color: var(--black);
  color: var(--black);
  
  &__item-icon::before {
    @include icon-color(var(--black));
  }
}
