table {
  border: var(--border-small) solid var(--white);
  font-size: var(--font-size-xs);
  margin: calc(var(--spacing-medium) - var(--spacing-xs)) 0;
  width: 100%;
  
  td,
  th {
    border: var(--border-xs) solid var(--white);
    padding: calc(var(--spacing-base)/2);
    vertical-align: top;
  }
  
  th,
  thead td {
    font-family: var(--font-family-futura-medium);
    line-height: var(--line-height-small);
  }

  @media(min-width: $lg) {
    font-size: var(--font-size-small);
    margin: var(--spacing-large) 0;

    td,
    th {
      padding: var(--spacing-base);
    }
  }
}

.section-block--bg-white,
.section-block--bg-light-green-blue {
  table,
  td,
  th {
    border-color: var(--black);
  }
}


