﻿.decoration {
    position: absolute;

    .timeline-window {
        position: relative;
        
        .parallax-wrapper {
            height: 100%;
        }
        
        img {
            height: 100%;
            float: left;
            max-width: none;
            width: auto;
        }
        
        // This prevents grabbing the image when drag-scrolling 
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
        }
    }
}

.illustration {
    &--old-suitcase {
        > .decoration {
            left: clamp(6rem,6vw,12rem);
        }
    }

    &--seat-1800 {
        > .decoration {
            left: clamp(9rem, 9vw, 18rem);
        }
    }

    &--seat-1900 {
        > .decoration {
            left: clamp(7rem,7vw,14rem);
        }
    }

    &--seat-2000 {
        > .decoration {
            left: clamp(9rem,9vw,18rem);
        }
    }
}